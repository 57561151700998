import * as React from 'react';
import styled from 'styled-components';
import {
  IValidatorManagerInnerProps,
  IValidatorRule,
  IValidatorStateManagerProps,
} from './ValidatorManager';
import { ITextAreaProps, Textarea, Color } from '@usga/modules';
import { ValidationTextWrapper } from './ValidatorInput';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const getValidationTextareaInner = <T extends string>(
  options: { disable?: boolean } & ITextareaProps
  // eslint-disable-next-line react/display-name
) => (props: IValidatorManagerInnerProps<T>) => {
  const { errors, value, onChange, onBlur } = props;

  const onChangeHandler = (event: React.SyntheticEvent<HTMLTextAreaElement>) =>
    onChange(event.currentTarget.value as T);

  const onBlurHandler = () => {
    onBlur();
  };

  const input = !options.disable && (
    <Textarea
      {...options}
      borderColor={errors && Color.ALERT}
      value={value}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
    />
  );

  return (
    <Wrapper>
      {input}
      <ValidationTextWrapper as={'div'}>{errors && errors[0]}</ValidationTextWrapper>
    </Wrapper>
  );
};

type ITextareaProps = Omit<
  React.InputHTMLAttributes<HTMLTextAreaElement>,
  'dangerouslySetInnerHTML'
> &
  ITextAreaProps;

export const ValidatorTextArea = <T extends string>({
  Connector,
  rules,
  ...textareaProps
}: {
  Connector: React.ComponentType<IValidatorStateManagerProps<T>>;
  rules: IValidatorRule<T>[];
} & ITextareaProps) => {
  const validationInputInner = React.useMemo(() => getValidationTextareaInner<T>(textareaProps), [
    textareaProps,
  ]);
  return <Connector rules={rules}>{validationInputInner}</Connector>;
};
