import { parsePhoneNumber, getCountryCallingCode } from '@usga/modules';

abstract class PhoneLengthRule {
  protected abstract readonly lessThan: number;

  protected abstract readonly equalOrGreaterThan: number;

  public validator<T extends string>(value: T) {
    return value.length >= this.equalOrGreaterThan && value.length < this.lessThan;
  }

  public get message() {
    return `Phone length must be less than ${this.lessThan}`;
  }
}

class DefaultPhoneLengthRule extends PhoneLengthRule {
  protected readonly lessThan: number = 14;

  protected readonly equalOrGreaterThan: number = 6;
}

class USPhoneLengthRule extends DefaultPhoneLengthRule {
  protected readonly lessThan = 11;

  protected readonly equalOrGreaterThan = 10;
}

class RUPhoneLengthRule extends DefaultPhoneLengthRule {
  protected readonly lessThan = 15;
}

class PhoneLengthRuleFactory {
  private static rulesMap = new Map<string | undefined, PhoneLengthRule>([
    [getCountryCallingCode('US'), new USPhoneLengthRule()],
    [getCountryCallingCode('RU'), new RUPhoneLengthRule()],
    [undefined, new DefaultPhoneLengthRule()],
  ]);

  public static formatPhone(phone: string) {
    return phone.charAt(0) === '+' ? phone : `+${phone}`;
  }

  public getRule(phone: string): PhoneLengthRule {
    const formattedValue = PhoneLengthRuleFactory.formatPhone(phone);
    const country = parsePhoneNumber(formattedValue)?.countryCallingCode;
    return PhoneLengthRuleFactory.rulesMap.get(country) || new DefaultPhoneLengthRule();
  }
}

const phoneLengthRuleFactory = new PhoneLengthRuleFactory();

export const PhoneRule = {
  validator: <T extends string>(value: T) => {
    if (!value) {
      return true;
    }
    const rule = phoneLengthRuleFactory.getRule(value);
    const phone = PhoneLengthRuleFactory.formatPhone(value);
    const numberOptions = parsePhoneNumber(phone);
    const nationalNumber = numberOptions?.nationalNumber;
    return rule.validator(nationalNumber ?? '');
  },
  message: 'Invalid phone length',
};
