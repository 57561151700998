import { ChangeEvent } from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { Color } from '../../helpers/Fonts';

export enum ResizeMode {
  NONE = 'none',
  BOTH = 'both',
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export interface ITextAreaProps {
  width?: string;
  height?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  borderColor?: Color;
  resize?: ResizeMode;
}

export const Textarea = styled(TextareaAutosize)<ITextAreaProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border: 1px solid ${({ borderColor }) => borderColor};
  resize: ${({ resize }) => resize};
  outline: none;
  border-radius: 4px;
  padding: 10px 14px;
  box-sizing: border-box;
`;

Textarea.defaultProps = {
  width: '100%',
  height: '100px',
  borderColor: Color.INPUT_BORDER,
  resize: ResizeMode.VERTICAL,
};
