import * as React from 'react';
import styled from 'styled-components';
import {
  IValidatorManagerInnerProps,
  IValidatorRule,
  IValidatorStateManagerProps,
} from './ValidatorManager';
import { IInputProps, Input, LabelMode, Text, Color, ITextProps } from '@usga/modules';

export const ValidationTextWrapper = styled(
  Text as React.ComponentType<React.ComponentProps<typeof Text>>
)<ITextProps & { position?: string }>`
  color: ${Color.ALERT};
  position: ${({ position }) => position || 'absolute'};
  top: 100%;
  left: 0;
  right: 0;
  margin: 0;
  font-size: 12px;
  line-height: 1;
`;

export const ValidationInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const getValidationInputInner = <T extends string>(
  options: {
    labelMode?: LabelMode;
    disableInput?: boolean;
    regExp?: RegExp;
    InputComponent?: React.ComponentType<React.ComponentProps<typeof Input>>;
  } & IInputProps
  // eslint-disable-next-line react/display-name
) => {
  const { min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER, type = 'text' } = options;

  return function ValidationInputInner(props: IValidatorManagerInnerProps<T>) {
    const { errors, value, onChange, onBlur } = props;

    const onChangeHandler = (event: React.SyntheticEvent<HTMLInputElement>) => {
      const val = event.currentTarget.value as T;
      if (options.regExp && !options.regExp.test(val)) {
        return;
      }
      if (type !== 'number') {
        onChange(val);
        return;
      }

      const valParsed = Number.parseFloat(val);
      if (Number.isNaN(valParsed)) {
        onChange(val);
        return;
      }

      onChange(String(Math.max(min, Math.min(max, valParsed))) as T);
    };

    const onBlurHandler = (evt?: React.FocusEvent<HTMLInputElement>) => {
      if (options.onBlur) {
        options.onBlur(evt);
      }
      onBlur(evt);
    };

    const InputComponent = options.InputComponent || Input;
    const input = !options.disableInput && (
      <InputComponent
        {...options}
        borderColor={errors && Color.ALERT}
        value={value}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
      />
    );

    return (
      <ValidationInputWrapper>
        {input}
        <ValidationTextWrapper as={'div'}>{errors && errors[0]}</ValidationTextWrapper>
      </ValidationInputWrapper>
    );
  };
};

export const ValidatorInput = <T extends string>({
  Connector,
  rules,
  ...inputProps
}: {
  Connector: React.ComponentType<IValidatorStateManagerProps<T>>;
  rules: IValidatorRule<T>[];
  labelMode?: LabelMode;
  regExp?: RegExp;
  InputComponent?: React.ComponentType<React.ComponentProps<typeof Input>>;
} & IInputProps) => {
  const validationInputInner = React.useMemo(() => getValidationInputInner<T>(inputProps), [
    inputProps,
  ]);
  return <Connector rules={rules}>{validationInputInner}</Connector>;
};

export const ValidatorHiddenInput = <T extends string>({
  Connector,
  rules,
  labelMode,
}: {
  Connector: React.ComponentType<IValidatorStateManagerProps<T>>;
  rules: IValidatorRule<T>[];
  labelMode?: LabelMode;
} & IInputProps) => {
  const validationInputInner = React.useMemo(
    () =>
      getValidationInputInner<T>({
        labelMode,
        disableInput: true,
      }),
    []
  );
  return <Connector rules={rules}>{validationInputInner}</Connector>;
};
