import * as React from 'react';
import {
  AmateurAppliancePlayerModel,
  ApplicationAdminResponseModelAllOfTeam,
  ApplicationAnonResponseModel,
  ApplicationResponseModel,
  ChampionshipPlayerModel,
  FourBallAppliancePlayerModel,
  OpenAppliancePlayerModel,
  USOpenAppliancePlayerModel,
} from '@usga/champadmin-api';
import { IAppAmateurAppliancePlayerModel } from './flows/AmateurFlow';
import { IAppOpenAppliancePlayerModel } from './flows/OpenFlow';
import { IAppFourBallAppliancePlayerModel } from './flows/FourballFlow';
import { IAppUSOpenAppliancePlayerModel } from './flows/UsopenFlow';
import { IAppAdaptiveAppliancePlayerModel } from './flows/AdaptiveFlow';

export type IApplianceReview = ApplicationResponseModel;

export interface IAmateurApplianceReviewComponentProps {
  review: ApplicationResponseModel;
  championship: ChampionshipPlayerModel;
  isAdaptiveOpen?: boolean;
}

export type IFourBallApplianceTeam = ApplicationAdminResponseModelAllOfTeam;

export type IGenericAppliance = ApplicationResponseModel;

export interface IApplianceStepManager<F> {
  goNext(data: Partial<F>): void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jumpTo(data: Partial<F>, component: React.ComponentType<any>): void;

  jumpToComponent(data: Partial<F>, stepIndex: number): void;

  goBack(data?: Partial<F>): void;

  updateData(data: Partial<F>): void;
}

export type IDefaultApplianceAEMData =
  | AmateurAppliancePlayerModel
  | OpenAppliancePlayerModel
  | FourBallAppliancePlayerModel
  | USOpenAppliancePlayerModel;

export type IDefaultApplianceAppData =
  | IAppAmateurAppliancePlayerModel
  | IAppOpenAppliancePlayerModel
  | IAppFourBallAppliancePlayerModel
  | IAppUSOpenAppliancePlayerModel
  | IAppAdaptiveAppliancePlayerModel;

export enum FlowType {
  Adaptive = 'Adaptive',
  Amateur = 'amateur',
  Open = 'open',
  Usopen = 'usopen',
  FourballFirst = 'fourball1',
  FourballSecond = 'fourball2',
  FourballSubstitution = 'FourballSubstitution',
}

export type IStepProps<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  steps: React.ComponentType<any>[];
  manager: IApplianceStepManager<T>;
  data: Partial<T> | undefined;
  initialData: Partial<T> | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: any;
  flowType: FlowType;
  showTimer?: any;
  reserveSpotId?: number;
  reserveSpotExpiry?: string;
  currentlyReservedSiteId?: number;
  initialReserveTime?: number;
  finalReserveTime?: number;
  onReservedSpotReleased?: () => void;
  gotoQualifyingStep?: (step?: number) => void;
  stepIndex: number;
  partnerApplication?: ApplicationAnonResponseModel;
} & IDefaultApplianceAppData;

export type ReviewFormConnectors = {
  firstName: string;
  lastName: string;
  guardianFirstName: string;
  guardianLastName: string;
  managerFirstName: string;
  managerLastName: string;
};

export type ReserveSpotResponse = {
  id: number;
  expiryTime: string;
};

export enum ReserveType {
  NORMAL = 0,
  WAITLIST = 1,
}
