import * as React from 'react';
import { FlexDirection, FlexAlignment, FlexWrapper, FlexWrap, Font, Text } from '@usga/modules';
import { ProfilePlayerModel } from '@usga/champadmin-api';
import {
  RegexpRule,
  RequiredRule,
  ValidatorInput,
  YearMask,
  YearRule as RegexpYearRule,
} from '../../../../../shared/Validator';
import { IApplicationHomeConnectors } from '../../ApplicationHomeAppliance';
import { IDoBRule, EmptyDoBRule, DayRule, MonthRule, YearRule } from './DoBRules';

export const ProfilePlayerModelContext = React.createContext<ProfilePlayerModel | null>(null);

interface IDobRules {
  dayRule: IDoBRule;
  monthRule: IDoBRule;
  yearRule: IDoBRule;
}

interface IDoBFieldsProps {
  connectors: IApplicationHomeConnectors;
  handleMonthBlur: () => void;
  handleDayBlur: () => void;
}

const EMPTY_DOB_RULES: IDobRules = {
  yearRule: new EmptyDoBRule(),
  dayRule: new EmptyDoBRule(),
  monthRule: new EmptyDoBRule(),
};

export function DoBFields({ connectors, handleMonthBlur, handleDayBlur }: IDoBFieldsProps) {
  const profile = React.useContext(ProfilePlayerModelContext);
  const dobRules = React.useMemo<IDobRules>(() => {
    if (!profile || !profile.dob) {
      return EMPTY_DOB_RULES;
    }
    return {
      dayRule: new DayRule(profile.dob),
      monthRule: new MonthRule(profile.dob),
      yearRule: new YearRule(profile.dob),
    };
  }, [profile]);
  return (
    <FlexWrapper
      direction={FlexDirection.ROW}
      justifyContent={FlexAlignment.BETWEEN}
      alignItems={FlexAlignment.START}
      wrap={FlexWrap.NOWRAP}
      padding={'10px 0'}
    >
      <FlexWrapper
        alignItems={FlexAlignment.START}
        wrap={FlexWrap.NOWRAP}
        basis={'33%'}
        padding={'0 10px 0 0'}
      >
        <FlexWrapper alignItems={FlexAlignment.START} basis={'initial'}>
          <Text font={Font.BOLD}>Month (MM)</Text>
        </FlexWrapper>
        <ValidatorInput
          Connector={connectors.monthValue}
          rules={[RequiredRule, RegexpRule(/^(1[012]|0?[1-9])$/), dobRules.monthRule]}
          placeholder={'01-12'}
          mask={[/[0-9]/, /[0-9]/]}
          onBlur={handleMonthBlur}
          autoComplete={'nofill'}
        />
      </FlexWrapper>
      <FlexWrapper
        alignItems={FlexAlignment.START}
        wrap={FlexWrap.NOWRAP}
        basis={'33%'}
        padding={'0 10px 0 0'}
      >
        <FlexWrapper alignItems={FlexAlignment.START} basis={'initial'}>
          <Text font={Font.BOLD}>Day (DD)</Text>
        </FlexWrapper>
        <ValidatorInput
          Connector={connectors.dayValue}
          rules={[
            RequiredRule,
            RegexpRule(/^(([0]?[1-9])|([1-2][0-9])|(3[01]))$/),
            dobRules.dayRule,
          ]}
          placeholder={'01-31'}
          mask={[/[0-9]/, /[0-9]/]}
          onBlur={handleDayBlur}
          autoComplete={'nofill'}
        />
      </FlexWrapper>
      <FlexWrapper alignItems={FlexAlignment.START} wrap={FlexWrap.NOWRAP} basis={'33%'}>
        <FlexWrapper alignItems={FlexAlignment.START} basis={'initial'}>
          <Text font={Font.BOLD}>Year (YYYY)</Text>
        </FlexWrapper>
        <ValidatorInput
          Connector={connectors.yearValue}
          rules={[RequiredRule, RegexpYearRule, dobRules.yearRule]}
          mask={YearMask}
          placeholder={'1995'}
          autoComplete={'nofill'}
        />
      </FlexWrapper>
    </FlexWrapper>
  );
}
