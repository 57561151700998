import React from 'react';
import {
  IValidatorManagerInnerProps,
  IValidatorRule,
  IValidatorStateManagerProps,
} from './ValidatorManager';
import { ValidationInputWrapper, ValidationTextWrapper } from './ValidatorInput';
import {
  DateTimePicker,
  IDateTimePickerProps,
  DateTimePickerEDT,
  LabelMode,
  Color,
} from '@usga/modules';
import moment from 'moment';

type DateTimePickerComponent = typeof DateTimePicker | typeof DateTimePickerEDT;

interface IValidatorDateTimeProps<T> extends Omit<IDateTimePickerProps, 'format'> {
  Connector: React.ComponentType<IValidatorStateManagerProps<T>>;
  rules: IValidatorRule<T>[];
  labelMode?: LabelMode;
  minDate?: Date;
  maxDate?: Date;
}

interface IValidatorDateTimePickerOptions extends Omit<IDateTimePickerProps, 'format'> {
  labelMode?: LabelMode;
  disableInput?: boolean;
  displayFormat?: string;
  valueFormat?: string;
  minDate?: Date;
  maxDate?: Date;
}

const getValue = (value: Date, format: string | undefined) => {
  if (format) {
    return moment(value).format(format);
  }

  return value.toISOString();
};

const getFormat = (value: Date | undefined, format: string | undefined) => {
  if (format) {
    return format;
  }

  return value ? `MM/dd/yyyy hh:mm:ss a` : `MM/dd/yyyy hh:mm:ss`;
};

const getValidationDatePickerInner = <T extends string>(
  options: IValidatorDateTimePickerOptions,
  Component: DateTimePickerComponent = DateTimePicker
  // eslint-disable-next-line react/display-name
) => (props: IValidatorManagerInnerProps<T>) => {
  const { errors, value, onChange, onBlur } = props;
  const onChangeHandler = React.useCallback(
    (value: Date) => {
      onChange(value ? (getValue(value, options.valueFormat) as T) : value);
    },
    [onChange, options.valueFormat]
  );

  const onBlurHandler = React.useCallback(() => {
    if (options.onBlur) {
      options.onBlur();
    }
    onBlur();
  }, [options, onBlur]);
  const dateValue = React.useMemo(() => {
    let dateValue: Date | undefined = moment(value).toDate();
    if (Number.isNaN(dateValue.getTime())) {
      dateValue = void 0;
    }
    return dateValue;
  }, [value]);

  const dateFormat = getFormat(dateValue, options.displayFormat);

  React.useEffect(() => {
    if (dateValue === undefined) {
      return;
    }
    const minDateTS = options.minDate?.getTime() ?? Number.MIN_SAFE_INTEGER;
    const maxDateTS = options.maxDate?.getTime() ?? Number.MAX_SAFE_INTEGER;
    const currentTS = dateValue.getTime();
    const newDate = Math.max(minDateTS, Math.min(maxDateTS, currentTS));
    if (newDate !== currentTS) {
      onChangeHandler(new Date(newDate));
    }
  }, [options.maxDate, options.minDate, dateValue, onChangeHandler]);

  const input = !options.disableInput && (
    <Component
      {...options}
      value={dateValue}
      format={dateFormat}
      borderColor={errors && Color.ALERT}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
      minDate={options.minDate}
      maxDate={options.maxDate}
    />
  );

  return (
    <ValidationInputWrapper>
      {input}
      <ValidationTextWrapper as={'div'}>{errors && errors[0]}</ValidationTextWrapper>
    </ValidationInputWrapper>
  );
};

export const ValidatorDateTimePicker = <T extends string>({
  Connector,
  rules,
  ...inputProps
}: IValidatorDateTimeProps<T>) => {
  const validationInputInner = getValidationDatePickerInner<T>(inputProps);
  return <Connector rules={rules}>{validationInputInner}</Connector>;
};

export const ValidatorDateTimePickerEDT = <T extends string>({
  Connector,
  rules,
  ...inputProps
}: IValidatorDateTimeProps<T>) => {
  const validationInputInner = getValidationDatePickerInner<T>(inputProps, DateTimePickerEDT);
  return <Connector rules={rules}>{validationInputInner}</Connector>;
};

export const ValidatorDatePicker = <T extends string>({
  Connector,
  rules,
  minDate,
  maxDate,
  ...inputProps
}: IValidatorDateTimeProps<T>) => {
  const validationInputInner = getValidationDatePickerInner<T>({
    ...inputProps,
    displayFormat: 'MM/dd/yyyy',
    valueFormat: 'YYYY-MM-DD',
    minDate,
    maxDate,
  });
  return <Connector rules={rules}>{validationInputInner}</Connector>;
};
