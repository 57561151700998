import * as React from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';

export interface IRadioButtonProps<T> {
  checked: boolean;
  value: T;
  name: string;
  onChange: (value: T) => void;
  className?: string;
  children?: React.ReactNode;
}

const RadioComponent = styled.div<{ checked: boolean }>`
  position: relative;
  width: 14px;
  height: 14px;
  min-width: 14px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  margin: 4px 10px 4px 0;
  border: 1px solid #ccc;
  box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0.1);

  &::after {
    display: ${(props) => (props.checked ? 'block' : 'none')};
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;
    width: 8px;
    height: 8px;
    background-color: #000;
    border-radius: 50%;
  }
`;

const Label = styled.label`
  display: flex;
  cursor: pointer;
`;

export function RadioButton<T extends boolean | string | number | null>(
  props: IRadioButtonProps<T>
) {
  const onChange = useCallback(() => props.onChange(props.value), [props.onChange, props.value]);
  return (
    <Label>
      <RadioComponent checked={props.checked} />
      {props.children}
      <input
        type="radio"
        name={props.name}
        value={props.value?.toString() ?? ''}
        checked={props.checked}
        hidden
        onChange={onChange}
      />
    </Label>
  );
}
