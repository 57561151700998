import * as React from 'react';
import { IValidatorManagerInnerProps, IValidatorStateManagerProps } from './ValidatorManager';
import { ValidationInputWrapper, ValidationTextWrapper } from './ValidatorInput';

type ITextType = string | null | number | undefined | boolean;

export interface IConnectedTextProps<T extends ITextType> {
  Connector: React.ComponentType<IValidatorStateManagerProps<T>>;
  id?: string;
  valueModifier?: (value: T) => React.ReactNode | null;
}

const getValidationTextInner = <T extends ITextType>(options: IConnectedTextProps<T>) => (
  props: IValidatorManagerInnerProps<T>
) => {
  return (
    <ValidationInputWrapper>
      <span id={options.id}>
        {options.valueModifier ? options.valueModifier(props.value) : props.value}
      </span>
      <ValidationTextWrapper as={'div'}>{props.errors && props.errors[0]}</ValidationTextWrapper>
    </ValidationInputWrapper>
  );
};

export function ConnectedValidatorText<T extends ITextType>(props: IConnectedTextProps<T>) {
  const validationTextInner = React.useMemo(() => getValidationTextInner<T>(props), [props]);

  const { Connector } = props;

  return <Connector rules={[]}>{validationTextInner}</Connector>;
}
