import * as React from 'react';
import {
  Button,
  FlexAlignment,
  FlexDirection,
  FlexWrapper,
  Font,
  Modal,
  Text,
} from '@usga/modules';
import styled from 'styled-components';
import { StyledButton } from '../../../QualifyingSitePopup/components';

type ReservedSpotModalProps = {
  text: string;
  isOpened: boolean;
  hideCloseButton?: boolean;
  showCancel?: boolean;
  onClose?: () => void;
  onSubmit: () => void;
  onCancel?: () => void;
};

const StyledModal = styled(Modal)`
  font-family: ${Font.BOOK};
`;

export default function ReservedSpotModal({
  text,
  isOpened,
  onClose,
  onSubmit,
  hideCloseButton,
  showCancel,
  onCancel,
}: ReservedSpotModalProps): JSX.Element {
  return (
    <StyledModal
      disableOverlayClick
      closeOnEscape={false}
      open={isOpened}
      onClose={onClose}
      hideCloseButton={hideCloseButton}
    >
      <FlexWrapper alignItems={FlexAlignment.CENTER} width="100%">
        <Text margin="20px 0">{text}</Text>
        <FlexWrapper direction={FlexDirection.ROW}>
          <Button className="default-button" onClick={onSubmit}>
            Continue
          </Button>
          {showCancel && (
            <Button className="default-button" onClick={onCancel} transparent>
              Cancel
            </Button>
          )}
        </FlexWrapper>
      </FlexWrapper>
    </StyledModal>
  );
}
