import * as React from 'react';
import { FieldWrapper, Font, Text } from '@usga/modules';
import { ConnectedValidatorText, IConnectedTextProps } from './ValidatorText';
import { IValidatorStateManagerProps } from './ValidatorManager';

interface IProps<T extends string> extends IConnectedTextProps<T> {
  title: string;
  Connector: React.ComponentType<IValidatorStateManagerProps<T>>;
  ComponentWrapper?: React.FunctionComponent;
}

export function ValidatorTitleText<T extends string>(props: IProps<T>) {
  const { ComponentWrapper, valueModifier, ...rest } = props;
  const Wrapper = ComponentWrapper || FieldWrapper;
  const inner = React.useCallback(
    (value: T) => {
      if (!value) {
        return null;
      }
      const renderValue = valueModifier ? valueModifier(value) : value;

      return (
        <Wrapper width={'200px'}>
          <Text font={Font.BOLD}>{props.title}</Text>
          <Text>{renderValue}</Text>
        </Wrapper>
      );
    },
    [Wrapper, valueModifier]
  );

  return <ConnectedValidatorText {...rest} valueModifier={inner} />;
}
