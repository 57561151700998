import * as React from 'react';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, switchMap, tap, debounceTime, filter } from 'rxjs/operators';
import { Color } from '../../helpers/Fonts';
import { reactSelectComponentsStyles, selectCss } from '../Select';
import { MIN_CHARS_TO_SEARCH } from '../../constants';

export type ReactSelectComponentsType = Partial<typeof components>;

export interface IValueProps {
  label: string;
  value: string;
}

export interface IAsyncSelectProps {
  loadItems: (inputVal: string) => Observable<IValueProps[]>;
  value: string | undefined | null;
  handleChange: (inputVal: string) => void;
  handlePick: (val: IValueProps) => void;
  id: string;
  width?: string;
  placeholder?: string;
  menuPlacement?: string;
  openMenuOnClick?: boolean;
  error?: boolean;
  handleEmptyMessage?: (obj: { inputValue: string }) => string | null;
  debounceRequest?: number;
  components?: ReactSelectComponentsType;
  noCache?: boolean;
}

const StyledSelect = styled(Select)`
  ${selectCss};
`;

export function AsyncSelect(props: IAsyncSelectProps) {
  const {
    handleChange,
    width,
    value,
    placeholder,
    handlePick,
    loadItems,
    id,
    openMenuOnClick,
    error,
    menuPlacement,
    handleEmptyMessage,
    debounceRequest,
    components,
    noCache,
  } = props;
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const openMenu = React.useCallback(() => setIsMenuOpen(true), []);
  const closeMenu = React.useCallback(() => setIsMenuOpen(false), []);
  const [isLoading, updateLoadingState] = React.useState(false);
  const setIsLoading = React.useCallback(() => {
    updateLoadingState(true);
  }, []);
  const unsetIsLoading = React.useCallback(() => {
    updateLoadingState(false);
  }, []);
  const [lastAction, setLastAction] = React.useState<'onInputChange' | 'onOptionChange' | ''>('');
  const [items, setItems] = React.useState<{ label: string; value: string }[]>([]);

  const searchStringSubject = React.useMemo(() => new Subject<string>(), []);

  React.useEffect(() => {
    const noCacheSubject = searchStringSubject.pipe(
      tap(() => setItems([])),
      filter((searchStr) => searchStr.length > MIN_CHARS_TO_SEARCH),
      debounceTime(debounceRequest || 0)
    );

    const subjectWithCache = searchStringSubject.pipe(
      filter((searchStr) => searchStr.length > MIN_CHARS_TO_SEARCH),
      debounceTime(debounceRequest || 0),
      distinctUntilChanged()
    );

    const sub$ = (noCache ? noCacheSubject : subjectWithCache)
      .pipe(tap(setIsLoading), switchMap(loadItems))
      .subscribe((items) => {
        setItems(items);
        unsetIsLoading();
      });

    return () => {
      sub$.unsubscribe();
    };
  }, [loadItems, debounceRequest, noCache]);

  const onOptionChange = React.useCallback(
    (option, { action }) => {
      if (action === 'select-option') {
        handlePick(option);
        setLastAction('onOptionChange');
      }
    },
    [handlePick]
  );

  const onInputChange = React.useCallback(
    (val, { action }) => {
      if (action === 'input-change') {
        handleChange(val);
        setLastAction('onInputChange');
      }
    },
    [handleChange]
  );

  React.useEffect(() => {
    if (!value || lastAction === 'onOptionChange') {
      return;
    }

    searchStringSubject.next(value);
  }, [value]);

  const optionVal = React.useMemo(() => {
    // option to flush current input option
    if (value === null) {
      return null;
    }

    const label = items.find((it) => it.value === value)?.label ?? value;

    if (!label && !value) return;

    return { label, value };
  }, [value, items]);

  return (
    <StyledSelect
      placeholder={placeholder || 'Type to search'}
      width={width || '100%'}
      options={items}
      value={optionVal}
      onChange={onOptionChange}
      onInputChange={onInputChange}
      openMenuOnClick={openMenuOnClick}
      menuIsOpen={isMenuOpen && value && value.length > MIN_CHARS_TO_SEARCH}
      onMenuOpen={openMenu}
      onMenuClose={closeMenu}
      isLoading={isLoading}
      inputId={id}
      borderColor={error && Color.ALERT}
      styles={reactSelectComponentsStyles}
      menuPlacement={menuPlacement}
      noOptionsMessage={handleEmptyMessage}
      components={components}
    />
  );
}
