import * as React from 'react';
import {
  CheckBox,
  LabelMode,
  FlexAlignment,
  FlexDirection,
  FlexWrap,
  FlexWrapper,
} from '@usga/modules';
import {
  IValidatorManagerInnerProps,
  IValidatorRule,
  IValidatorStateManagerProps,
} from './ValidatorManager';
import { ValidationTextWrapper } from './ValidatorInput';

const innerCheckbox = <T extends boolean | null>(options: {
  children?: JSX.Element | null;
  labelMode?: LabelMode;
  id?: string;
  disabled?: boolean;
}) =>
  // eslint-disable-next-line react/display-name
  (props: IValidatorManagerInnerProps<T>) => {
    const { errors } = props;

    const onClick = React.useCallback(() => {
      props.onChange(!props.value as T);
      props.onBlur();
    }, [props.onChange, props.onBlur, props.value]);
    const label = options.children && (
      <FlexWrapper alignItems={FlexAlignment.START} flex={'1'}>
        <label htmlFor={options.id}>{options.children || null}</label>
      </FlexWrapper>
    );
    return (
      <>
        <FlexWrapper
          wrap={FlexWrap.NOWRAP}
          direction={FlexDirection.ROW}
          justifyContent={FlexAlignment.START}
          alignItems={FlexAlignment.START}
          flex={'1'}
        >
          <CheckBox
            active={!!props.value}
            onClick={onClick}
            id={options.id}
            disabled={options.disabled}
          />
          {label}
        </FlexWrapper>
        <ValidationTextWrapper as={'div'}>{errors && errors[0]}</ValidationTextWrapper>
      </>
    );
  };

export const ValidatorCheckbox = <T extends boolean | null>({
  rules,
  Connector,
  ...props
}: {
  Connector: React.ComponentType<IValidatorStateManagerProps<T>>;
  rules: IValidatorRule<T>[];
  children?: JSX.Element;
  labelMode?: LabelMode;
  disabled?: boolean;
  id?: string; // todo make id required
}) => {
  return <Connector rules={rules}>{innerCheckbox(props)}</Connector>;
};
