import * as React from 'react';
import { IValidatorForm } from './ValidatorForm';

export const useFormField: <T extends object, K extends keyof T>(
  form: IValidatorForm<T>,
  field: K
) => [T[K], (val: T[K]) => void] = <T extends object, K extends keyof T>(
  form: IValidatorForm<T>,
  field: K
) => {
  const [value, setValue] = React.useState(form.getProperty(field));
  React.useEffect(() => {
    const sub = form.observeProperty(field).subscribe((val) => {
      setValue(val);
    });
    return () => sub.unsubscribe();
  }, [form, field]);

  const onChange = React.useCallback((val: T[K]) => form.changeProperty(field, val), [form, field]);
  return [value, onChange];
};

interface IFormFieldProps<T extends object, K extends keyof T> {
  form: IValidatorForm<T>;
  field: K;
  children: (params: [T[K], (val: T[K]) => void]) => React.ReactNode;
}

export const FormField = <T extends object, K extends keyof T>({
  children,
  field,
  form,
}: IFormFieldProps<T, K>) => {
  const params = useFormField(form, field);
  return <>{children(params)}</>;
};
