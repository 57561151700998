import * as React from 'react';
import styled from 'styled-components';

export interface ICheckboxProps {
  id?: string;
  active: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

const Check = styled.input`
  position: relative;
  width: 14px;
  height: 14px;
  min-width: 14px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  margin: 4px 10px 4px 0;
  flex-shrink: 0;
`;

export function CheckBox(props: ICheckboxProps) {
  return (
    <Check
      type={'checkbox'}
      checked={props.active}
      onChange={props.onClick}
      id={props.id}
      disabled={props.disabled}
    />
  );
}
