import { get, mergeWith, set, flatten } from 'lodash';
import { IValidatorForm } from '../components/shared/Validator';
import { IObjectMapperSchema, objectMapper } from './objectMapper';

export type ISchemaTransformEntryV2<S, T, Type> = {
  default: Type;
  transform: (target: T, source: S) => Type | undefined;
  transformInverse: (value: Type, target: T, source: S) => S;
  originalField: string | string[] | ((target: T, source: S) => string);
  // remove this field if it's alone at object
  stripIfAlone?: boolean;
};

export type ISchemaPathEntryV2<Type> = {
  default: Type;
  path: string;
  stripIfAlone?: boolean;
};

type ISchemaEntryV2<S, T, Type> =
  | ISchemaTransformEntryV2<S, T, Required<Type>>
  | ISchemaPathEntryV2<Type>;

export type ISchemaPropertyV2<S, T, K extends keyof T> =
  | ISchemaEntryV2<S, T, T[K]>
  | IObjectMapperSchemaV2<S, T>;

export type IObjectMapperSchemaV2<S, T> = {
  [K in keyof T]: ISchemaPropertyV2<S, T, K>;
};

function isTransformEntry<S, T, K extends keyof T>(
  input: ISchemaPropertyV2<S, T, K>
): input is ISchemaTransformEntryV2<S, T, Required<T[K]>> {
  return 'transform' in input && 'transformInverse' in input;
}

function isPathEntry<S, T, K extends keyof T>(
  input: ISchemaPropertyV2<S, T, K>
): input is ISchemaPathEntryV2<T[K]> {
  return 'path' in input;
}

type IInverseMeta<S, T> = {
  stripIfAloneFields: Array<string | ((target: T, source: S) => string)>;
  inverseActions: ((target: T, source: S) => void)[];
  // original field -> form field
  fieldsMap: Map<string, string[]>;
  // form field -> original field
  fieldsLookupData: Map<string, (target: T, source: S) => string>;
};

export class ObjectMapperV2<S, T extends object> {
  private readonly meta: IInverseMeta<Partial<S>, T>;

  constructor(private schema: IObjectMapperSchemaV2<Partial<S>, T>) {
    this.meta = this.prepareMeta();
  }

  getFieldsByOriginalPath(originalPath: string, source: Partial<S>, target: T): string[] {
    const field = this.meta.fieldsMap.get(originalPath);
    if (field) {
      const objectKey = `${originalPath}.`;
      const relatedFields = flatten(
        [...this.meta.fieldsMap]
          .filter(([key]) => key.startsWith(objectKey))
          .map(([, value]) => value)
      );

      return [...field, ...relatedFields];
    }
    for (const [formField, lookup] of this.meta.fieldsLookupData) {
      if (lookup(target, source) === originalPath) {
        return [formField];
      }
    }

    return [];
  }

  fromSourceToTarget(source: Partial<S>) {
    return objectMapper(source, this.schema as IObjectMapperSchema<Partial<S>, T>);
  }

  fromTargetToSource(target: T): Partial<S> {
    const source = {};
    this.meta.inverseActions.forEach((action) => action(target, source));
    return source;
  }

  mergeDataWithForm(source: Partial<S>, form: IValidatorForm<T>, visibleOnly = true): S {
    const target = form.getFormData(visibleOnly);
    const formData = this.fromTargetToSource(target);

    const data = mergeWith({}, source, formData, (objValue, srcValue) => {
      if (Array.isArray(srcValue)) {
        return srcValue;
      }
    }) as S;

    const stripIfAlonePaths = new Set(
      this.meta.stripIfAloneFields.map((field) => {
        return typeof field === 'string' ? field : field(target, source);
      })
    );

    return this.stripForm(data, stripIfAlonePaths);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private stripForm<F extends { [index: string]: any }>(
    d: F,
    stripIfAlonePaths: Set<string>,
    prependPath: string[] = []
  ): F {
    const data = { ...d };
    Object.keys(data).forEach((k) => {
      const fullPath = [...prependPath, k];
      const key = k as keyof F;
      if (Array.isArray(data[key])) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data[key] = data[key].map((value: any) => {
          return typeof data[key] === 'object' && !Array.isArray(data[key]) && data[key] !== null
            ? this.stripForm(value, stripIfAlonePaths, fullPath)
            : value;
        });
        // strip each object
      } else if (typeof data[key] === 'object' && data[key] !== null) {
        data[key] = this.stripForm(data[key], stripIfAlonePaths, fullPath);

        const keys = Object.keys(data[key]);
        const sufficientKeys =
          stripIfAlonePaths && stripIfAlonePaths.size > 0
            ? keys.filter((k) => {
                return !stripIfAlonePaths.has([...fullPath, k].join('.'));
              })
            : keys;
        if (sufficientKeys.length < 1) {
          delete data[key];
        }
      } else if (data[key] === null || data[key] === '' || data[key] === undefined) {
        delete data[key];
      }
    });
    return data;
  }

  private prepareMeta(): IInverseMeta<Partial<S>, T> {
    const meta: IInverseMeta<Partial<S>, T> = {
      inverseActions: [],
      stripIfAloneFields: [],
      fieldsMap: new Map(),
      fieldsLookupData: new Map(),
    };

    function addValue(metaMap: Map<string, string[]>, key: string, value: string) {
      const fieldValue = metaMap.get(key);
      if (fieldValue) {
        const newValue = [...fieldValue, value];
        metaMap.set(key, newValue);
      } else {
        metaMap.set(key, [value]);
      }
    }

    function processObject(
      schema: IObjectMapperSchemaV2<Partial<S>, T>,
      prependPath: string[] = []
    ) {
      Object.keys(schema).forEach((key) => {
        const fullPath = [...prependPath, key];
        const schemaValue: ISchemaPropertyV2<Partial<S>, T, keyof T> = schema[key as keyof T];

        const originalField =
          ('originalField' in schemaValue && schemaValue.originalField) ||
          ('path' in schemaValue && schemaValue.path);
        if (originalField) {
          if (Array.isArray(originalField)) {
            originalField.forEach((field) => {
              const valueKey = field;
              const value = fullPath.join('.');
              addValue(meta.fieldsMap, valueKey, value);
            });
            if ('stripIfAlone' in schemaValue && schemaValue.stripIfAlone) {
              throw new Error('not implemented');
            }
          } else if (typeof originalField === 'string') {
            const valueKey = originalField;
            const value = fullPath.join('.');
            addValue(meta.fieldsMap, valueKey, value);
            if ('stripIfAlone' in schemaValue && schemaValue.stripIfAlone) {
              meta.stripIfAloneFields.push(originalField);
            }
          } else {
            meta.fieldsLookupData.set(fullPath.join('.'), originalField);
          }
        }

        if (isTransformEntry(schemaValue)) {
          meta.inverseActions.push((target, source) => {
            const targetValue = get(target, fullPath);
            schemaValue.transformInverse(targetValue, target, source);
          });
        } else if (isPathEntry(schemaValue)) {
          meta.inverseActions.push((target, source) => {
            const targetValue = get(target, fullPath);
            set((source as unknown) as object, schemaValue.path, targetValue);
          });
        } else {
          processObject(schemaValue as IObjectMapperSchemaV2<Partial<S>, T>, fullPath);
        }
      });
    }

    processObject(this.schema);

    return meta;
  }
}
