import * as React from 'react';
import { RadioButton } from '@usga/modules';
import {
  IValidatorManagerInnerProps,
  IValidatorRule,
  IValidatorStateManagerProps,
} from './ValidatorManager';
import { ValidationTextWrapper } from './ValidatorInput';

// eslint-disable-next-line react/display-name
const getRadioInner = <T extends boolean | string | null>(options: {
  value: T;
  name: string;
  children: React.ReactNode;
}) => (props: IValidatorManagerInnerProps<T>) => {
  const { onChange, errors, value, onBlur } = props;

  const onChangeHandler = React.useCallback(
    (v: T) => {
      onChange(v);
      onBlur();
    },
    [onBlur]
  );
  return (
    <>
      <RadioButton
        checked={value === options.value}
        value={options.value}
        onChange={onChangeHandler}
        name={options.name}
      >
        {options.children}
      </RadioButton>
      <ValidationTextWrapper as={'div'}>{errors && errors[0]}</ValidationTextWrapper>
    </>
  );
};

// fixme imitate real radio input
export const ValidatorRadio = <T extends boolean | string | null>({
  Connector,
  rules,
  value,
  name,
  children,
}: {
  Connector: React.ComponentType<IValidatorStateManagerProps<T>>;
  rules: IValidatorRule<T>[];
  value: T;
  name: string;
  children: React.ReactNode;
}) => {
  const options = { value, children, name };
  return <Connector rules={rules}>{getRadioInner(options)}</Connector>;
};
