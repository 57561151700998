import moment from 'moment';
import postalCodes from 'postal-codes-js';
import { isEmail, isNumeric } from 'validator';
import { DOLLAR_PREFIX } from '@usga/modules';
import {
  ChampionshipMinorityModel,
  ChampionshipPlayerModel,
  GenderEnum,
  WorkflowTypeEnum,
} from '@usga/champadmin-api';
import { replaceApostrophes } from '../../../../services/replace-apostrophies';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export interface IGeneralInformationBirthValue {
  year: string;
  month: string;
  day: string;
  playerMinor: boolean | null;
  startDate: string;
}

export interface IAllowOppositeGenderValidatorValue {
  hIndex?: number;
  maxHIndex: number;
  value: GenderEnum;
}

export const defaultGenderRule = (gender: GenderEnum, message: string) => ({
  validator: (data: IAllowOppositeGenderValidatorValue) => data.value === gender,
  message,
});

export const allowOppositeGenderRule = (gender: GenderEnum, message: string) => ({
  validator: (data: IAllowOppositeGenderValidatorValue) => ({
    isValid: data.value === gender,
    isWarning: true,
    message: message,
  }),
});

// source: https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
export const ValidURLRule = (() => {
  const allowedProtocols = ['http', 'https'];
  return {
    validator: <T extends string>(value: T) => {
      if (!value) return true;

      try {
        const url = new URL(value);
        // remove last ':' character
        const protocol = url.protocol.slice(0, -1);
        return allowedProtocols.includes(protocol);
      } catch {
        return false;
      }
    },
    message: 'Incorrect URL',
  };
})();

export const RequiredRule = {
  validator: <T extends unknown>(value: T) => !!value,
  message: () => 'Field is required',
};

export const RequiredRuleForBoolean = {
  validator: <T extends unknown>(value: T) => value !== undefined && value !== null,
  message: () => 'Field is required',
};

export const WatchlistReasonRule = {
  validator: <T extends unknown>(value: T) => !!value,
  message: () => 'Please put a reason',
};

export const NonEmptyArrayRule = {
  validator: <T extends Array<unknown>>(value: T) => !!value.length,
  message: 'Field is required',
};

export const NotInArrayRule = <T extends unknown>(
  array: T[],
  message = 'Value is repeated',
  allowEmptyValue = true
) => ({
  validator: (value: T) => {
    if (allowEmptyValue && (value === '0' || !value)) {
      return true;
    }

    return !array.includes(value);
  },
  message,
});

export const EmailRule = {
  validator: <T extends string>(value: T) => {
    if (!value) {
      return true;
    }

    return isEmail(value);
  },
  message: 'Invalid email format',
};

export const MultipleEmailRule = {
  validator: <T extends string>(value: T) => {
    if (!value) {
      return true;
    }

    const trimmedVal = value.trim();
    const regex = /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]+[\W]*;{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]+)[\W]*$/;

    return regex.test(trimmedVal);
  },
  message: 'Field should contain only emails separated by semicolon',
};

export const NotEmailRule = {
  validator: <T extends string>(value: T) => !isEmail(value),
  message: 'Field should not be an email',
};

export const NotInIdArrRule = (arr: number[]) => ({
  validator: <T extends unknown>(value: T) => !arr.includes(Number(value)),
  message: 'This item already exist',
});

export const RegexpRule = (regexp: RegExp) => ({
  validator: <T extends string>(value: T) => regexp.test(value),
  message: 'Field is not valid',
});

export const PostalCodeRule = (countryCode: string, message = 'Postal code is not valid') => ({
  validator: <T extends string>(value: T) => {
    if (!value || !countryCode) {
      return true;
    }

    if (countryCode === 'CA') {
      const regex = /^[ABCEGHJKLMNPRSTVXYWZ]\d[ABCEGHJKLMNPRSTVXYWZ][ -]?\d[ABCEGHJKLMNPRSTVXYWZ]\d$/;
      return regex.test(value);
    }

    const validity = postalCodes.validate(countryCode, value);
    if (typeof validity === 'boolean') {
      return validity;
    }

    return false;
  },
  message,
});

export const NumericRule = {
  validator: <T extends string>(value: T) => !value || isNumeric(value),
  message: 'Field must be a number',
};

export const RadioNotEmptyRule = {
  validator: <T extends string | null | boolean>(value: T) => value !== null && value !== '',
  message: 'Must select at least one option.',
};

export const MaxLengthRule = (number: number) => ({
  validator: <T extends string>(value: T) => {
    if (!value) {
      return true;
    }

    return value.length <= number;
  },
  message: `Field length must be less than ${number}`,
});

export const MinNumberRule = (number: number) => ({
  validator: <T extends string>(value: T) => isNumeric(value) && parseFloat(value) >= number,
  message: `Should be at least ${number}`,
});

export const MaxNumberRule = (number: number) => ({
  validator: <T extends string>(value: T) => isNumeric(value) && parseFloat(value) <= number,
  message: `Value must be less than ${number}`,
});

export const MinNumberOrEmptyRule = (number: number | undefined | null | string) => ({
  validator: <T extends string>(value: T) =>
    !isNumeric(value) ||
    (isNumeric(value) && parseFloat(value) >= Number.parseFloat(String(number))),
  message: `Value must be less than ${number}`,
});
export const MaxNumberOrEmptyRule = (number: number | undefined | null | string) => ({
  validator: <T extends string>(value: T) =>
    !isNumeric(value) ||
    (isNumeric(value) && parseFloat(value) <= Number.parseFloat(String(number))),
  message: `Value must be less than ${Number(number) + 1}`,
});

export const EvenNumberRule = (message: string) => {
  return {
    validator: <T extends string>(value: T) => isNumeric(value) && parseFloat(value) % 2 === 0,
    message,
  };
};

export function WaitlistNumberRule(workflowType: WorkflowTypeEnum | undefined) {
  const max = workflowType === WorkflowTypeEnum.FOURBALL ? 20 : 10;
  return {
    validator: <T extends string>(value: T) =>
      !isNumeric(value) || (isNumeric(value) && parseFloat(value) <= max),
    message: `Waitlist Max Capacity is ${max}`,
  };
}

export const PositiveNumberRule = {
  validator: <T extends string>(value: T) => Number(value) >= 0,
  message: 'Field must be a positive number',
};

export const NotZeroDollarRule = {
  validator: <T extends string>(value: T) => parseFloat(value.replace(DOLLAR_PREFIX, '')) !== 0,
  message: 'Field must be a positive number',
};

export const AcceptedRule = {
  validator: <T extends boolean>(value: T) => value,
  message: 'Field must be accepted',
};

export const RequireBooleanRule = {
  validator: <T extends unknown>(value: T) => typeof value === 'boolean',
  message: 'Field is required',
};

export const NotNullRule = {
  validator: <T extends boolean | null>(value: T) => value !== null,
  message: 'Field must be chosen',
};

export const YearRule = RegexpRule(/^\d{4}$/);

export const getAge = (
  { year, month, day }: { year: string; month: string; day: string },
  date?: string
) => {
  const birthday =
    (parseInt(year) &&
      parseInt(month) &&
      parseInt(day) &&
      moment(`${year}-${month}-${day}`, DEFAULT_DATE_FORMAT)) ||
    moment();
  const startDate = date ? moment(date) : moment();

  return startDate.diff(birthday, 'years');
};

export const isPlayerMinor = ({
  year,
  month,
  day,
}: {
  year: string;
  month: string;
  day: string;
}) => {
  return getAge({ year, month, day }) <= 17;
};

export const minorRule = (minority: ChampionshipMinorityModel) => ({
  validator: (data: IGeneralInformationBirthValue) => {
    if (!data.playerMinor || !minority.enabled) {
      return true;
    }

    const age = getAge(data);

    return age < (minority.transitionBorderFromMinorToAdult ?? 0);
  },
  message: minority.minorCheckedButOlder,
});

export const adultRule = (minority: ChampionshipMinorityModel) => ({
  validator: (data: IGeneralInformationBirthValue) => {
    if (data.playerMinor || data.playerMinor === null || !minority.enabled) {
      return true;
    }

    const age = getAge(data);

    return age >= (minority.transitionBorderFromMinorToAdult ?? 0);
  },
  message: minority.adultCheckedButYounger,
});

export const minAgeRule = (championship: ChampionshipPlayerModel) => ({
  validator: (data: IGeneralInformationBirthValue) => {
    if (!championship.ageLimit?.min) {
      return true;
    }

    const age = getAge(data, data.startDate);

    return age >= (championship.ageLimit?.min ?? 0);
  },
  message: championship.ageLimit?.minMessage,
});

export const maxAgeRule = (championship: ChampionshipPlayerModel) => ({
  validator: (data: IGeneralInformationBirthValue) => {
    if (!championship.ageLimit?.max) {
      return true;
    }

    const age = getAge(data, data.startDate);

    return age < (championship.ageLimit?.max ?? 0);
  },
  message: championship.ageLimit?.maxMessage,
});

export const matchStringRule = (valueToCheck: string, message: string) => ({
  validator: (value: string) => {
    return (
      replaceApostrophes(value.trim().toLowerCase()) ===
      replaceApostrophes(valueToCheck.trim().toLowerCase())
    );
  },
  message,
});

export const maxSizeLimitRule = (bytes: number) => ({
  validator: (files: File[]) => {
    return (files[0]?.size ?? 0) < bytes;
  },
  message: `Max file size is ${bytes} bytes`,
});

export const YearMask = [/[1-9]/, /\d/, /\d/, /\d/];
