import * as React from 'react';
import { IValidatorForm } from './ValidatorForm';
import { share, switchMap } from 'rxjs/operators';
import isEqual from 'lodash/isEqual';
import { AccordionContext, IAccordionContext } from '@usga/modules';

export interface IValidatorManager {
  field: string;
  validate(
    hit?: IValidationResult
  ): Promise<{ errors: IValidationResult[]; manager: IValidatorManager } | undefined>;
  getOffsetTop(): number | undefined;
  getElement(): HTMLElement | null;
  scrollToElement(): void;
}

export type IValidatorManagerInnerProps<T> = {
  value: T;
  onChange: (value: T) => void;
  onBlur: (evt?: React.FocusEvent<HTMLElement>) => void;
  isDirty: boolean;
  isTouched: boolean;
  errors?: string[];
};

export interface IValidationResult {
  isValid: boolean;
  isWarning: boolean;
  message?: string;
}

export type IValidatorRule<T> = {
  validator: (value: T) => boolean | IValidationResult | Promise<boolean | IValidationResult>;
  message?: string | ((field: string) => string | JSX.Element);
};

interface IValidatorConnectionProps<F extends object, K extends keyof F> {
  form: IValidatorForm<F>;
  field: K;
  accordion: IAccordionContext | null;
}
export interface IValidatorStateManagerProps<T> {
  rules: Array<IValidatorRule<T>>;
  children: (args: IValidatorManagerInnerProps<T>) => JSX.Element | null;
}

interface IValidationStateManagerState<T> {
  isTouched: boolean;
  isDirty: boolean;
  errors: string[] | undefined;
  value: T;
}

const convertBooleanToValidationResult = (isValid: boolean) => {
  /* if (process.env.NODE_ENV === 'development') {
    console.warn(`Your validator rule on field ${field} returns boolean.
    Consider to use IValidationResult instead`);
  }*/
  return {
    isValid,
    isWarning: false,
  };
};

export const processRules = <F extends object, T>(
  value: T,
  rules: Array<IValidatorRule<T>>,
  form: IValidatorForm<F>,
  field: string
): Promise<IValidationResult[] | undefined> => {
  const processRule = (rule: IValidatorRule<T>) => {
    const result = rule.validator(value);
    let promise$: Promise<IValidationResult>;
    if (typeof result === 'boolean') {
      promise$ = Promise.resolve(convertBooleanToValidationResult(result));
    } else if ('then' in result) {
      promise$ = result.then((r) => {
        if (typeof r === 'boolean') {
          return convertBooleanToValidationResult(r);
        } else {
          return r;
        }
      });
    } else {
      promise$ = Promise.resolve(result);
    }
    return promise$.then((result) => {
      if (!result.isValid) {
        let message;
        if (result.message) {
          message = result.message;
        } else if (rule.message) {
          message = typeof rule.message === 'string' ? rule.message : rule.message(field);
        }
        return {
          ...result,
          message,
        };
      }
    });
  };
  const promises$ = rules.map(processRule);
  return Promise.all(promises$).then((errors) => {
    const filtered = errors.filter((e) => e != null) as IValidationResult[];
    if (filtered.length) {
      return filtered;
    }
  });
};

const InnerRenderer = <T extends unknown>(
  props: IValidatorManagerInnerProps<T> & {
    children: (args: IValidatorManagerInnerProps<T>) => JSX.Element | null;
  }
) => {
  const { children, ...rest } = props;
  return <>{children(rest)}</>;
};

class ValidationStateManager<F extends object, K extends keyof F>
  extends React.Component<
    IValidatorConnectionProps<F, K> & IValidatorStateManagerProps<F[K]>,
    IValidationStateManagerState<F[K]>
  >
  implements IValidatorManager {
  public field: string;

  private wrapperRef = React.createRef<HTMLAnchorElement>();

  private cleanUp?: () => void;

  constructor(props: IValidatorConnectionProps<F, K> & IValidatorStateManagerProps<F[K]>) {
    super(props);
    this.field = props.field as string;
    this.state = {
      isTouched: false,
      isDirty: false,
      errors: undefined,
      value: props.form.getProperty(props.field),
    };
  }

  componentDidMount(): void {
    const validatorSub = this.props.form.registerValidator(String(this.props.field), this);
    const observable = this.props.form.observeProperty(this.props.field).pipe(share());
    const valueSub = observable.subscribe((value) => {
      this.setState({ value });
    });
    const errorSub = observable
      .pipe(switchMap((value) => this.getErrors(value)))
      .subscribe((errors) => {
        const mappedErrors = errors ? errors.map((e) => e.message ?? '') : undefined;
        if (!isEqual(this.state.errors, mappedErrors)) {
          console.log(Date.now(), this.state.errors, mappedErrors);
          this.setState({
            errors: mappedErrors,
          });
        }
      });

    this.cleanUp = () => {
      validatorSub();
      valueSub.unsubscribe();
      errorSub.unsubscribe();
    };
  }

  componentWillUnmount(): void {
    this.cleanUp && this.cleanUp();
  }

  onChange = (value: F[K]) => {
    this.setState({
      isDirty: true,
    });
    this.changeForm(value);
  };

  onBlur = () => {
    this.setState({
      isTouched: true,
    });
  };

  changeForm(value: F[K]) {
    this.props.form.changeProperty(this.props.field, value);
  }

  getErrors = (value: F[K]) => {
    return processRules(value, this.props.rules, this.props.form, this.props.field as string);
  };

  render() {
    const innerProps = {
      value: this.state.value,
      onChange: this.onChange,
      errors: this.state.errors,
      isDirty: this.state.isDirty,
      isTouched: this.state.isTouched,
      onBlur: this.onBlur,
    };
    return (
      <>
        <a ref={this.wrapperRef} className={'validation-anchor'} />
        <InnerRenderer {...innerProps}>{this.props.children}</InnerRenderer>
      </>
    );
  }

  getOffsetTop() {
    const managerTop = this.wrapperRef.current?.getBoundingClientRect().top;
    const accordionTop = this.props.accordion?.ref.current?.getElement()?.getBoundingClientRect()
      .top;
    const top = managerTop ?? accordionTop;
    return top != null ? top + window.scrollY : undefined;
  }

  getElement() {
    return this.wrapperRef.current;
  }

  scrollToElement(): void {
    if (this.props.accordion && !this.props.accordion.isOpen) {
      this.props.accordion.changeIsOpen(true, () =>
        this.getElement()?.scrollIntoView({ block: 'center' })
      );
    } else {
      this.getElement()?.scrollIntoView({ block: 'center' });
    }
  }

  validate(
    hit?: IValidationResult
  ): Promise<{ errors: IValidationResult[]; manager: IValidatorManager } | undefined> {
    this.setState({
      isDirty: true,
      isTouched: true,
    });

    return this.getErrors(this.state.value).then((errors) => {
      const withHit: IValidationResult[] | undefined = hit
        ? errors
          ? [hit, ...errors]
          : [hit]
        : errors;
      this.setState({
        isTouched: true,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        errors: withHit ? withHit.map((e) => e.message!) : undefined, // fixme
      });
      if (withHit) {
        return { errors: withHit, manager: this as IValidatorManager };
      }
    });
  }
}

export type IValidationConnector<T> = React.ComponentType<IValidatorStateManagerProps<T>>;
type ICreateConnector = <F extends object, K extends keyof F>(
  form: IValidatorForm<F>,
  field: K
) => IValidationConnector<F[K]>;

export const createConnector: ICreateConnector = (form, field) => {
  return function WithFormConnection(props) {
    const accordionContext = React.useContext(AccordionContext);
    return (
      <ValidationStateManager form={form} field={field} {...props} accordion={accordionContext} />
    );
  };
};
