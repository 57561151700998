import * as React from 'react';
import styled from 'styled-components';
import {
  IValidatorManagerInnerProps,
  IValidatorRule,
  IValidatorStateManagerProps,
} from './ValidatorManager';
import { ValidationTextWrapper } from './ValidatorInput';

const ValidatorValueWrapper = styled(
  ValidationTextWrapper as React.ComponentType<React.ComponentProps<typeof ValidationTextWrapper>>
)`
  top: auto;
  bottom: -4px;
  left: 10px;
`;

interface IProps<T> {
  Connector: React.ComponentType<IValidatorStateManagerProps<T>>;
  value?: T;
  rules?: Array<IValidatorRule<T>>;
  children?: (errors: Array<string> | undefined) => React.ReactNode;
  hideError?: boolean;
}

export function ValidatorValue<T>(props: IProps<T>) {
  const { Connector, children, hideError } = props;

  const inner = React.useCallback(
    (params: IValidatorManagerInnerProps<T>) => {
      React.useEffect(() => {
        if (props.value && params.value !== props.value) {
          params.onChange(props.value);
        }
      }, [props.value, params.value]);

      if (children) {
        return <>{children(params.errors)}</>;
      } else if (!params.errors || hideError) {
        return null;
      } else {
        return (
          <ValidatorValueWrapper as={'div'}>
            {params.errors && params.errors[0]}
          </ValidatorValueWrapper>
        );
      }
    },
    [props.value, hideError]
  );

  return <Connector rules={props.rules ?? []}>{inner}</Connector>;
}
