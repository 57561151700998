import { BehaviorSubject, Observable } from 'rxjs';
import {
  createConnector,
  IValidationConnector,
  IValidationResult,
  IValidatorManager,
} from './ValidatorManager';
import flatten from 'lodash/flatten';
import minBy from 'lodash/minBy';
import { distinctUntilChanged, map, share } from 'rxjs/operators';

type IRegisterValidator = <K extends string>(field: K, validator: IValidatorManager) => () => void;

export interface IValidatorForm<F extends object> {
  registerValidator: IRegisterValidator;
  validateAll: () => Promise<string[] | undefined | void>;
  validate<K extends keyof F>(field: K, goToAnchor?: boolean): Promise<string[] | undefined | void>;
  changeProperty<K extends keyof F>(key: K, value: F[K]): void;
  getProperty<K extends keyof F>(key: K): F[K];
  observeProperty<K extends keyof F>(key: K): Observable<F[K]>;
  observeForm(): Observable<F>;
  getFormData(visibleOnly?: boolean): F;
  setFormData(data: F): void;
  createConnectors(): FormConnectors<Required<F>>;
}

export type FormConnectors<F extends object> = {
  [K in keyof F]: IValidationConnector<F[K]>;
};

export class ValidatorForm<F extends object> implements IValidatorForm<F> {
  private validators: { [key: string]: IValidatorManager[] } = {};
  private form: BehaviorSubject<F>;

  constructor(form: F) {
    this.form = new BehaviorSubject(form);
  }

  createConnectors(): FormConnectors<Required<F>> {
    return Object.keys(this.form.getValue()).reduce(
      (acc, key) => {
        acc[key] = createConnector(this, key as keyof F);
        return acc;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {} as any
    );
  }

  changeProperty<K extends keyof F>(key: K, value: F[K]) {
    const form = this.form.getValue();
    this.form.next({
      ...form,
      [key]: value,
    });
  }

  getProperty<K extends keyof F>(key: K): F[K] {
    return this.form.getValue()[key];
  }

  observeProperty<K extends keyof F>(key: K): Observable<F[K]> {
    return this.form.pipe(
      map((form) => form[key]),
      distinctUntilChanged(),
      share()
    );
  }

  registerValidator: IRegisterValidator = (field, validator) => {
    if (!this.validators[field]) {
      this.validators[field] = [];
    }
    this.validators[field].push(validator);
    return () => {
      const validatorsArray = this.validators[field];
      validatorsArray.splice(
        validatorsArray.findIndex((v) => v === validator),
        1
      );
    };
  };

  validateAll(goToAnchor = true): Promise<string[] | undefined | void> {
    const validators = flatten(Object.keys(this.validators).map((key) => this.validators[key]));

    return this.checkValidators(validators, goToAnchor);
  }

  validate<K extends keyof F>(field: K, goToAnchor = true): Promise<string[] | undefined | void> {
    // todo fix typings
    const validators = flatten(
      Object.keys(this.validators[field as string]).map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (key) => this.validators[field as string][key as any]
      )
    );

    return this.checkValidators(validators, goToAnchor);
  }

  getFormData(visibleOnly = false): F {
    const value = this.form.getValue();
    if (visibleOnly) {
      const visibleKeys = new Set(
        Object.keys(this.validators).filter((key) => this.validators[key].length > 0)
      );
      const filtered: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const mapped = Object.keys(value).reduce((acc: any, key) => {
        if (visibleKeys.has(key)) {
          acc[key] = value[key as keyof F];
        } else {
          acc[key] = null;
          filtered.push(key);
        }
        return acc;
      }, {} as F);
      return mapped;
    } else {
      return { ...value };
    }
  }

  setFormData(data: F): void {
    this.form.next(data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  assignToForm(...args: any[]) {
    return args.reduce((acc, current) => {
      Object.keys(current).forEach((key) => {
        if (acc[key]) {
          console.error(`Property ${key} already exists in form`);
        }
        acc[key] = current[key];
      });
      return acc;
    }, {});
  }

  public observeForm(): Observable<F> {
    return this.form.asObservable();
  }

  protected checkValidators(
    validators: IValidatorManager[],
    goToAnchor: boolean
  ): Promise<string[] | undefined | void> {
    return Promise.all(validators.map((validator: IValidatorManager) => validator.validate())).then(
      (validatorErrors) => {
        return this.processErrors(validatorErrors, goToAnchor);
      }
    );
  }

  protected processErrors(
    errors: (
      | {
          errors: IValidationResult[];
          manager: IValidatorManager;
        }
      | undefined
    )[],
    goToAnchor: boolean
  ) {
    const validationErrors = errors.filter(
      (e) => e != null && e.errors.some((it) => !it.isWarning)
    ) as {
      errors: IValidationResult[];
      manager: IValidatorManager;
    }[];
    const hasErrorSeverity = validationErrors.some(
      (e) => e.errors.filter((er) => !er.isWarning).length
    );
    if (validationErrors.length && hasErrorSeverity) {
      if (goToAnchor) {
        minBy(validationErrors, (v) => v.manager.getOffsetTop())?.manager.scrollToElement();
      }
      throw flatten(validationErrors.map((e) => e.errors));
    }
  }
}
