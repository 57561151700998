import { Observable, BehaviorSubject } from 'rxjs';
import { switchMap, debounceTime, share } from 'rxjs/operators';
import { IValidatorRule } from './ValidatorManager';

interface IOptions<T> {
  initial: T;
  validate: (value: T) => Observable<boolean>;
  debounceInterval?: number;
  message?: IValidatorRule<T>['message'];
}

const DEFAULT_DEBOUNCE_INTERVAL = 500;

export class BackendValidation<T extends {}> implements IValidatorRule<T> {
  private readonly validate: IOptions<T>['validate'];

  private readonly internalMessage?: IOptions<T>['message'];

  private readonly subject: BehaviorSubject<T>;

  private readonly observable$: Observable<boolean>;

  constructor({
    initial,
    validate,
    message,
    debounceInterval = DEFAULT_DEBOUNCE_INTERVAL,
  }: IOptions<T>) {
    this.validate = validate;
    this.internalMessage = message;
    this.subject = new BehaviorSubject(initial);
    this.observable$ = this.subject.pipe(
      debounceTime(debounceInterval),
      switchMap((value) => this.validate(value)),
      share()
    );
  }

  public validator = (value: T) => {
    this.subject.next(value);
    return new Promise<boolean>((resolve) => {
      this.observable$.subscribe((res) => {
        resolve(res);
      });
    });
  };

  public get message() {
    return this.internalMessage;
  }
}
