import * as React from 'react';
import { FlexWrapper, Text } from '@usga/modules';
import moment, { Moment } from 'moment';

type CountdownTimerProps = {
  startTime?: Moment;
  limitInMinutes?: number;
  style: object;
  onReservedSpotExpired: () => void;
};

export default function CountdownTimer({
  style,
  limitInMinutes,
  onReservedSpotExpired,
}: CountdownTimerProps): JSX.Element {
  const [diff, setDiff] = React.useState(limitInMinutes?.toString());
  const [endTime] = React.useState(moment().add(limitInMinutes, 'minutes'));
  React.useEffect(() => {
    const timer = setInterval(function() {
      const now = moment();
      if (endTime > now) {
        const diff = moment.utc(endTime.diff(now)).format('mm:ss');
        setDiff(diff);
      } else {
        clearInterval(timer);
        onReservedSpotExpired();
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <FlexWrapper style={style}>
      <Text margin="20px 0">
        You have <b>{diff}</b> minutes to continue to the payment page to secure your spot in the
        selected qualifying location.
      </Text>
    </FlexWrapper>
  );
}
