import * as React from 'react';
import styled from 'styled-components';
import { IRichEditorProps, RichEditor, Color } from '@usga/modules';
import { ValidationTextWrapper } from './ValidatorInput';
import {
  IValidatorManagerInnerProps,
  IValidatorRule,
  IValidatorStateManagerProps,
} from './ValidatorManager';

type RichEditorOptions = Pick<IRichEditorProps, 'overrideConfig'>;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const RichEditorBorder = styled.div<{ error: boolean }>`
  width: 100%;
  border-radius: 3px;
  border: ${({ error }) => {
    return `1px solid ${error ? Color.ALERT : 'transparent'}`;
  }};
`;

function getValidationRichEditorInner(
  overrideConfig: IRichEditorProps['overrideConfig'] | undefined
) {
  return function RichEditorInner(props: IValidatorManagerInnerProps<string>) {
    // const { errors, value, onChange, onBlur } = props;
    const handleBlur = React.useCallback(
      (value: string) => {
        props.onChange(value);
        props.onBlur();
      },
      [[props.onBlur, props.onChange]]
    );

    return (
      <Wrapper>
        <RichEditorBorder error={Boolean(props.errors && props.errors[0])}>
          <RichEditor value={props.value} onBlur={handleBlur} overrideConfig={overrideConfig} />
        </RichEditorBorder>
        <ValidationTextWrapper as="div">{props.errors && props.errors[0]}</ValidationTextWrapper>
      </Wrapper>
    );
  };
}

interface IValidatorRichEditProps extends RichEditorOptions {
  Connector: React.ComponentType<IValidatorStateManagerProps<string>>;
  rules: Array<IValidatorRule<string>>;
}

export function ValidatorRichText(props: IValidatorRichEditProps) {
  const Connector = props.Connector;
  const validationInputInner = React.useMemo(() => {
    return getValidationRichEditorInner(props.overrideConfig);
  }, [props.overrideConfig]);
  return <Connector rules={props.rules}>{validationInputInner}</Connector>;
}
