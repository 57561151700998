import * as React from 'react';
import { forkJoin } from 'rxjs';
import { filter, map, switchMap, distinctUntilChanged } from 'rxjs/operators';
import {
  useMessageBus,
  isApplicationEvent,
  isPartnerApplicationEvent,
  IApplicationEvent,
  IPartnerApplicationEvent,
} from '../../../services/MessageBus';
import {
  ExemptionModel,
  QualifyingSitePlayerFullModel,
  WaitListAlternateTypeEnum,
  ApplicationPlayerModelAllOfQualifyingSites,
} from '@usga/champadmin-api';
import { useHttpService } from '../../../services';
import { GenericFlowComponent } from '../../appliances/flows/GenericFlow/GenericFlowComponent';

const filterUndefined = <T extends {}>(x: T | undefined): x is T => Boolean(x);

interface IQualificationSitesAndExemptions {
  qualifyingSiteItems: QualifyingSitePlayerFullModel[];
  exemptionItems: ExemptionModel[];
  waitListItems: QualifyingSitePlayerFullModel[];
}

export const useQualificationSiteAndExemption = (): IQualificationSitesAndExemptions => {
  const httpService = useHttpService();
  const messageBusContext = useMessageBus();
  const [qualifyingSites, setQualifyingSites] = React.useState<QualifyingSitePlayerFullModel[]>([]);
  const [exemptions, setExemptions] = React.useState<ExemptionModel[]>([]);
  const [application, setApplication] = React.useState<IApplicationEvent>();
  const [partnerApplication, setPartnerApplication] = React.useState<IPartnerApplicationEvent>();

  const qualifyingSites$ = messageBusContext.pipe(
    filter(isApplicationEvent),
    map((message) => {
      if (!message.payload.championship) {
        throw new Error('Championship is not ready');
      }
      return message.payload.championship.id;
    }),
    distinctUntilChanged(),
    switchMap((champId) =>
      forkJoin([
        httpService.defaultApi('binChampadminCommonQualifyingsiteGet')(undefined, champId, -1),
        httpService.defaultApi('binChampadminCommonExemptionGet')(Number(champId), undefined, -1),
      ])
    ),
    map(([qualifyingSite, exemptions]) => ({
      qualifyingSiteItems: qualifyingSite.data.items,
      exemptionItems: exemptions.data.items,
    }))
  );

  const partner$ = messageBusContext.pipe(
    filter(isPartnerApplicationEvent),
    distinctUntilChanged()
  );

  React.useEffect(() => {
    const sub = messageBusContext.pipe(filter(isApplicationEvent)).subscribe(setApplication);
    return () => sub.unsubscribe();
  }, []);

  React.useEffect(() => {
    const sub = partner$.subscribe(setPartnerApplication);
    return () => sub.unsubscribe();
  }, []);

  React.useEffect(() => {
    const sub = qualifyingSites$.subscribe(({ qualifyingSiteItems, exemptionItems }) => {
      GenericFlowComponent.meta.exemptions = exemptionItems;
      GenericFlowComponent.meta.qualifyingSites = qualifyingSiteItems;
      setQualifyingSites(qualifyingSiteItems);
      setExemptions(exemptionItems);
    });

    return () => sub.unsubscribe();
  }, []);

  return React.useMemo(() => {
    const qSites = partnerApplication?.payload.qualifyingSites ?? [];
    const wl = partnerApplication?.payload.waitList ?? [];
    const ex = partnerApplication?.payload.exemption ?? [];
    const model = application?.payload;

    const sites: Array<ApplicationPlayerModelAllOfQualifyingSites & {
      alternateType?: WaitListAlternateTypeEnum;
    }> = [...(model?.qualifyingSites ?? []), ...qSites];
    const waitList: Array<ApplicationPlayerModelAllOfQualifyingSites & {
      alternateType?: WaitListAlternateTypeEnum;
    }> = [...(model?.waitList ?? []), ...wl];

    return {
      qualifyingSiteItems: sites
        .map((site) => {
          const foundSite = qualifyingSites.find((item) => item.id === site.qualifyingSite?.id);

          if (!foundSite) {
            return;
          }

          return { ...foundSite, alternateType: site.alternateType };
        })
        .filter(filterUndefined),
      waitListItems: waitList
        .map((site) => {
          const foundSite = qualifyingSites.find((item) => item.id === site.qualifyingSite?.id);

          if (!foundSite) {
            return;
          }

          return { ...foundSite, alternateType: site.alternateType };
        })
        .filter(filterUndefined),
      exemptionItems: [...(model?.exemption ?? []), ...ex]
        .map((exemptionItem) => exemptions.find((item) => item.id === exemptionItem.id))
        .filter(filterUndefined),
    };
  }, [qualifyingSites, exemptions, application, partnerApplication]);
};
