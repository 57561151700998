import * as React from 'react';
import { LabelMode, ISelectProps, Select, Color } from '@usga/modules';
import {
  IValidatorManagerInnerProps,
  IValidatorRule,
  IValidatorStateManagerProps,
} from './ValidatorManager';
import { ValidationTextWrapper } from './ValidatorInput';

// it is just a callback
// eslint-disable-next-line react/display-name
const getValidatorSelectInner = <T extends unknown>(options: IMappedSelectProps<T>) => (
  props: IValidatorManagerInnerProps<T>
) => {
  const { onChange, value, errors, onBlur } = props; // fixme add rest

  const handleChange = React.useCallback(
    (val) => {
      if (val == null) {
        if (options.isMulti) {
          onChange([] as T);
        } else {
          onChange('' as T);
        }
      } else {
        onChange(val as T);
      }
    },
    [onChange]
  );
  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <Select
        {...options}
        id={options.id}
        items={options.items}
        menuPlacement={options.menuPlacement}
        val={value}
        handleChange={handleChange}
        borderColor={errors && Color.ALERT}
        onBlur={onBlur}
      />
      <ValidationTextWrapper as={'div'}>{errors && errors[0]}</ValidationTextWrapper>
    </div>
  );
};

interface IMappedSelectProps<T> extends ISelectProps<T> {
  errorMode?: LabelMode;
}

interface IValidatorSelectProps<T> extends IMappedSelectProps<T> {
  Connector: React.ComponentType<IValidatorStateManagerProps<T>>;
  rules: IValidatorRule<T>[];
}

export const ValidatorSelect = <T extends unknown>({
  Connector,
  rules,
  ...props
}: IValidatorSelectProps<T>) => {
  const inner = getValidatorSelectInner<T>(props);
  return <Connector rules={rules}>{inner}</Connector>;
};
