import { get, isObject, mergeWith, set } from 'lodash';
import { IValidatorForm } from '../components/shared/Validator';
import { IObjectMapperSchemaV2, ISchemaTransformEntryV2 } from './objectMapperV2';

export function createEnumMapping<T>(path: string): ISchemaPathEntry<T | null> {
  return {
    default: null,
    path,
  };
}

export function createStringMapping(path: string) {
  return {
    default: '',
    path,
  };
}

export function createBooleanMapping(path: string, initial: boolean | null = null) {
  return {
    default: initial,
    path,
  };
}

export function createNumberToStringMapping(
  path: string,
  defaultValue?: string,
  allowZero = false
) {
  return {
    default: defaultValue ?? '',
    transform: <T, S>(t: T, s: S) => {
      const value = Number(get(s, path));

      if (Number.isNaN(value)) {
        return defaultValue || '';
      }

      if (!allowZero && value === 0) {
        return '';
      }

      return value.toString();
    },
    transformInverse: <T, S>(v: string, t: T, s: S) => {
      const numValue = parseFloat(v);

      if (v === '' && isObject(s)) {
        set(s, path, v);

        return s;
      }

      if (!Number.isNaN(numValue) && isObject(s)) {
        set(s, path, numValue);
      }
      return s;
    },
    originalField: path,
  };
}

export function createEmptyArrayMapping<
  S,
  T,
  K extends keyof S & string,
  Type extends S[K] & Array<unknown>
>(path: K): ISchemaTransformEntryV2<S, T, Type> {
  const value = ([] as Array<unknown>) as Type;
  return {
    default: value,
    transform: (t, s) => s[path] as Type,
    transformInverse: (v, t, s) => {
      s[path] = value;
      return s;
    },
    originalField: path,
  };
}

export type ISchemaTransformEntry<S, T, Type> = {
  default: Type;
  transform: (target: T, source: S) => Type | undefined;
  transformInverse: (value: Type, target: T, source: S) => S;
};
export type ISchemaPathEntry<Type> = {
  default: Type;
  path: string;
  inverseAllowEmpty?: boolean;
};
type ISchemaEntry<S, T, Type> =
  | ISchemaTransformEntry<S, T, Required<Type>>
  | ISchemaPathEntry<Type>;

type ISchemaProperty<S, T, K extends keyof T> =
  | ISchemaEntry<S, T, T[K]>
  | IObjectMapperSchema<S, T>;

export type IObjectMapperSchema<S, T> = {
  [K in keyof T]: ISchemaProperty<S, T, K>;
};

type IMapper = <S, T>(src: S, schema: IObjectMapperSchema<S, T> | IObjectMapperSchemaV2<S, T>) => T;
type IInverseMapper = <S, T>(
  target: T,
  schema: IObjectMapperSchema<S, T>,
  omitEmpty?: boolean,
  prependPath?: string[]
) => S;

function isTransformEntry<S, T, K extends keyof T>(
  input: ISchemaProperty<S, T, K>
): input is ISchemaTransformEntry<S, T, Required<T[K]>> {
  return 'transform' in input && 'transformInverse' in input;
}

function isPathEntry<S, T, K extends keyof T>(
  input: ISchemaProperty<S, T, K>
): input is ISchemaPathEntry<T[K]> {
  return 'path' in input;
}

export const objectMapper: IMapper = <S, T>(src: S, schema: IObjectMapperSchema<S, T>): T => {
  return Object.keys(schema).reduce((acc, key) => {
    const schemaValue = schema[key as keyof T];
    if (isTransformEntry(schemaValue)) {
      const val = schemaValue.transform(acc, src);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      acc[key as keyof T] = (val !== undefined ? val : schemaValue.default) as any;
    } else if (isPathEntry(schemaValue)) {
      acc[key as keyof T] = get(src, schemaValue.path, schemaValue.default);
    } else {
      acc[key as keyof T] = objectMapper(
        src,
        schemaValue as IObjectMapperSchema<S, unknown>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) as any;
    }
    return acc;
  }, {} as T);
};

export const inverseObjectMapper: IInverseMapper = <S, T>(
  target: T,
  schema: IObjectMapperSchema<S, T>,
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  omitEmpty: boolean = true,
  prependPath: string[] = []
): S => {
  return Object.keys(schema).reduce((acc, key) => {
    const fullPath = [...prependPath, key];
    const schemaValue = schema[key as keyof T];
    const targetValue = get(target, fullPath);
    const isEmpty = targetValue === '' || targetValue == null;
    if (isTransformEntry(schemaValue)) {
      acc = schemaValue.transformInverse(targetValue, target, acc);
    } else if (isPathEntry(schemaValue)) {
      if (!isEmpty || !omitEmpty || (isEmpty && schemaValue.inverseAllowEmpty)) {
        set((acc as unknown) as object, schemaValue.path, targetValue);
      }
    } else {
      console.error('Deep schema is not tested properly');
      acc = inverseObjectMapper(
        target,
        schemaValue as IObjectMapperSchema<S, unknown>,
        omitEmpty,
        fullPath
      );
    }
    return acc;
  }, {} as S);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function stripForm<T extends { [index: string]: any }>(d: T): T {
  const data = { ...d };
  Object.keys(data).forEach((k) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const key = k as keyof T;
    if (Array.isArray(data[key])) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data[key] = data[key].map((value: any) => {
        return typeof data[key] === 'object' && data[key] !== null ? stripForm(value) : value;
      });
      // strip each object
    } else if (typeof data[key] === 'object' && data[key] !== null) {
      data[key] = stripForm(data[key]);

      if (Object.keys(data[key]).length < 1) {
        delete data[key];
      }
    } else if (data[key] === null || data[key] === '' || data[key] === undefined) {
      delete data[key];
    }
  });
  return data;
}

type IMergeDataWithForm = <S, T extends object>(
  src: S,
  schema: IObjectMapperSchema<Partial<S>, T>,
  form: IValidatorForm<T>
) => S;

export const mergeDataWithForm: IMergeDataWithForm = (src, schema, form) => {
  const formData = inverseObjectMapper(form.getFormData(true), schema, false);

  const data = mergeWith({}, src, formData, (objValue, srcValue) => {
    if (Array.isArray(srcValue)) {
      return srcValue;
    }
  });

  return stripForm(data);
};
