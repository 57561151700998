import * as React from 'react';
import { LabelMode, Checkbox as AdminCheckBox, Cursor, Text, Font } from '@usga/modules';
import {
  IValidatorManagerInnerProps,
  IValidatorRule,
  IValidatorStateManagerProps,
} from './ValidatorManager';
import { ValidationTextWrapper } from './ValidatorInput';

export type ICheckboxOptions = {
  children?: React.ReactNode;
  labelMode?: LabelMode;
  id?: string;
  disabled?: boolean;
  margin?: string;
};

const innerAdminCheckbox = <T extends boolean | null>(options: ICheckboxOptions) =>
  // eslint-disable-next-line react/display-name
  (props: IValidatorManagerInnerProps<T>) => {
    const { errors } = props;

    const onClick = React.useCallback(() => {
      props.onChange(!props.value as T);
      props.onBlur();
    }, [props.onChange, props.onBlur, props.value]);
    return (
      <>
        <AdminCheckBox
          checked={!!props.value}
          onChange={onClick}
          id={options.id}
          disabled={options.disabled}
        >
          <Text
            font={Font.BOLD}
            as={'span'}
            margin={options.margin ?? '0 0 0 5px'}
            cursor={Cursor.INHERIT}
          >
            {options.children}
          </Text>
        </AdminCheckBox>
        <ValidationTextWrapper as={'div'}>{errors && errors[0]}</ValidationTextWrapper>
      </>
    );
  };

export const ValidatorAdminCheckbox = <T extends boolean | null>({
  rules,
  Connector,
  ...props
}: ICheckboxOptions & {
  Connector: React.ComponentType<IValidatorStateManagerProps<T>>;
  rules: IValidatorRule<T>[];
}) => {
  return <Connector rules={rules}>{innerAdminCheckbox(props)}</Connector>;
};
