import styled from 'styled-components';
import { SCREEN } from '@usga/modules';

export const StepsWrapper = styled.div`
  padding: 0 10px;
  background-color: #fff;

  ${SCREEN.TL} {
    padding: 0 70px;
  }
`;
