import React from 'react';
import { LabelMode, Color, IPhoneInputProps, PhoneInput } from '@usga/modules';
import {
  IValidatorManagerInnerProps,
  IValidatorRule,
  IValidatorStateManagerProps,
} from './ValidatorManager';
import { ValidationInputWrapper, ValidationTextWrapper } from './ValidatorInput';

const getValidationInputInner = <T extends string>(
  options: { labelMode?: LabelMode; disableInput?: boolean } & IPhoneInputProps
  // eslint-disable-next-line react/display-name
) => (props: IValidatorManagerInnerProps<T>) => {
  const { errors, value, onChange, onBlur } = props;

  const onChangeHandler = (value?: string) => onChange((value ?? '') as T);

  const onBlurHandler = () => {
    onBlur();
  };

  const input = !options.disableInput && (
    <PhoneInput
      {...options}
      value={value}
      borderColor={errors && Color.ALERT}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
    />
  );

  return (
    <ValidationInputWrapper>
      {input}
      <ValidationTextWrapper as={'div'}>{errors && errors[0]}</ValidationTextWrapper>
    </ValidationInputWrapper>
  );
};

export const ValidatorPhoneInput = <T extends string>({
  Connector,
  rules,
  ...inputProps
}: {
  Connector: React.ComponentType<IValidatorStateManagerProps<T>>;
  rules: IValidatorRule<T>[];
  labelMode?: LabelMode;
} & IPhoneInputProps) => {
  const validationInputInner = getValidationInputInner<T>(inputProps);
  return <Connector rules={rules}>{validationInputInner}</Connector>;
};
