import * as React from 'react';
import debounce from 'lodash/debounce';
import { AsyncSelect, IAsyncSelectProps, ReactSelectComponentsType } from '@usga/modules';
import {
  IValidatorManagerInnerProps,
  IValidatorRule,
  IValidatorStateManagerProps,
} from './ValidatorManager';
import { ValidationTextWrapper } from './ValidatorInput';

interface IMappedAsyncSelectProps<T>
  extends Omit<IAsyncSelectProps, 'handleChange' | 'handlePick' | 'value'> {
  onPick?: (value: T) => void;
  menuPlacement?: string;
  debounceRequest?: number;
  components?: ReactSelectComponentsType;
}

interface IValidatorAsyncSelectProps<T> extends IMappedAsyncSelectProps<T> {
  Connector: React.ComponentType<IValidatorStateManagerProps<T>>;
  rules: IValidatorRule<T>[];
}

const getValidatorAsyncSelectInner = <T extends string>(options: IMappedAsyncSelectProps<T>) => (
  props: IValidatorManagerInnerProps<T>
) => {
  const { onChange, value, errors } = props;

  const debouncedLoadItems = React.useMemo(() => {
    return debounce(options.loadItems, options.debounceRequest || 0);
  }, [options.loadItems, options.debounceRequest]);

  const handleChange = React.useCallback(
    (inputVal) => {
      onChange(inputVal);
      debouncedLoadItems(inputVal);
    },
    [debouncedLoadItems]
  );

  const handlePick = React.useCallback(
    (option) => {
      onChange(option.value);

      if (options.onPick) {
        options.onPick(option.value);
      }
    },
    [options]
  );
  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
        textTransform: props.value === 'club' ? 'capitalize' : 'none',
      }}
    >
      <AsyncSelect
        {...options}
        handlePick={handlePick}
        debounceRequest={options.debounceRequest}
        value={value}
        handleChange={handleChange}
        error={!!errors?.length}
        menuPlacement={options.menuPlacement}
        components={options.components}
      />
      <ValidationTextWrapper as={'div'}>{errors && errors[0]}</ValidationTextWrapper>
    </div>
  );
};

export function ValidatorAsyncSelect<T extends string>({
  Connector,
  rules,
  ...props
}: IValidatorAsyncSelectProps<T>) {
  const inner = getValidatorAsyncSelectInner<T>(props);
  return <Connector rules={rules}>{inner}</Connector>;
}
