import * as React from 'react';
import {
  FlexAlignment,
  FlexWrap,
  FlexWrapper,
  AccordionContext,
  IAccordionContext,
  LabelMode,
  RichText,
  Text,
  Color,
} from '@usga/modules';
import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';
import {
  IValidationResult,
  IValidatorManager,
  IValidatorRule,
  processRules,
} from './ValidatorManager';
import { IValidatorForm } from './ValidatorForm';

type IProps<F extends object, T> = {
  labelMode?: LabelMode;
  rules: IValidatorRule<T>[];
  field?: string;
  form: IValidatorForm<F>;
  value: T;
  errorMessage?: string;
  html?: boolean;
};

interface IOwnProps {
  accordion: IAccordionContext | null;
}

type IState<T> = {
  errors: string[] | undefined;
  isTouched: boolean;
};

class ValidatorTipComponent<F extends object, T>
  extends React.Component<IProps<F, T> & IOwnProps, IState<T>>
  implements IValidatorManager {
  public field: string;

  private validatorSub?: () => void;

  private validationAnchor = React.createRef<HTMLAnchorElement>();

  constructor(props: IProps<F, T> & IOwnProps) {
    super(props);
    this.field = props.field ?? '';
    this.state = {
      errors: undefined,
      isTouched: false,
    };
  }

  componentDidMount(): void {
    this.validatorSub = this.props.form.registerValidator('other', this);
  }

  componentDidUpdate(prevProps: IProps<F, T>) {
    if (isObject(prevProps.value) && isObject(this.props.value)) {
      if (!isEqual(prevProps.value, this.props.value)) {
        return this.validate();
      }
    } else {
      if (prevProps.value !== this.props.value) {
        this.validate();
      }
    }
  }

  componentWillUnmount(): void {
    this.validatorSub && this.validatorSub();
  }

  render() {
    const { children } = this.props;

    return (
      <FlexWrapper wrap={FlexWrap.NOWRAP} alignItems={FlexAlignment.START} flex={'initial'}>
        <a className={'validator-anchor'} ref={this.validationAnchor} />
        {children}
        <FlexWrapper flex={'initial'} basis={'100%'} alignItems={FlexAlignment.START}>
          <Text as={'div'} margin={'10px 0'} color={Color.ALERT}>
            {this.errorMessage}
          </Text>
        </FlexWrapper>
      </FlexWrapper>
    );
  }

  validate: () => Promise<
    { manager: IValidatorManager; errors: IValidationResult[] } | undefined
  > = () => {
    this.setState({
      isTouched: true,
    });
    return this.getErrors();
  };

  getOffsetTop(): number | undefined {
    const managerTop = this.validationAnchor.current?.getBoundingClientRect().top;
    const accordionTop = this.props.accordion?.ref.current?.getElement()?.getBoundingClientRect()
      .top;
    const top = managerTop ?? accordionTop;
    return top != null ? top + window.scrollY : undefined;
  }

  getElement(): HTMLElement | null {
    return this.validationAnchor.current;
  }

  scrollToElement(): void {
    if (this.props.accordion && !this.props.accordion.isOpen) {
      this.props.accordion.changeIsOpen(true, () => this.getElement()?.scrollIntoView());
    } else {
      this.getElement()?.scrollIntoView();
    }
  }

  private getErrors() {
    const { rules, field, form, value } = this.props;
    return processRules(value, rules, form, field || '').then((errors) => {
      this.setState({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        errors: errors ? errors.map((e) => e.message!) : undefined,
      });
      if (errors) {
        return {
          errors,
          manager: this as IValidatorManager,
        };
      }
    });
  }

  private get errorMessage() {
    const { html, errorMessage } = this.props;
    const { errors } = this.state;
    let error = errors && errors[0];

    if (errorMessage) {
      error = errors && errorMessage;
    }

    return html ? <RichText html={error || ''} /> : error;
  }
}

export const ValidatorTip = <F extends object, T>(props: React.PropsWithChildren<IProps<F, T>>) => {
  const accordion = React.useContext(AccordionContext);
  return <ValidatorTipComponent {...props} accordion={accordion} />;
};
