export const MIN_CHARS_TO_SEARCH = 2;
export const SPOT_RESERVATION_FAILED_MESSAGE =
  'There are no available spots for the qualifying location that you selected. Please reselect a qualifying location.';
export const SPOT_RESERVATION_SUCCESS_MESSAGE =
  'Please be advised that you have xx minutes to continue to the payment page to secure your spot in the selected qualifying location. If time runs out, you will forfeit your spot and will need to reselect a qualifying location.';
export const SPOT_RESERVATION_PAYMENT_MESSAGE =
  'Please be advised that you have xx minutes to complete the payment to secure your spot in the selected qualifying location. If time expires or you leave the payment page without submitting payment, you will forfeit your spot and will need to reselect a qualifying location.';
export const SPOT_RESERVATION_RELEASE_WARNING =
  'Please be advised that you will forfeit your spot in the qualifying location and will need to reselect a qualifying location.';
export const SPOT_RESERVATION_EXPIRED_MESSAGE =
  'The time to reserve your qualifying location has expired. Please reselect a qualifying location.';
