import moment from 'moment';

export interface IDoBRule {
  validator: (value: string) => boolean;
  message: () => string;
}

export class EmptyDoBRule implements IDoBRule {
  private static DOB_FORMAT = 'YYYY-MM-DD';
  private playerDoB?: moment.Moment;
  constructor(playerDoBStr?: string) {
    if (playerDoBStr) {
      this.playerDoB = moment(playerDoBStr, EmptyDoBRule.DOB_FORMAT);
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public validator(_: unknown) {
    return true;
  }
  public message() {
    return '';
  }
  protected check(value: number, getValue: (playerDob: moment.Moment) => number) {
    return this.isEmpty(value) || !this.playerDoB || value === getValue(this.playerDoB);
  }
  private isEmpty(dayOfBirth: number) {
    return Number.isNaN(dayOfBirth);
  }
}

export class DayRule extends EmptyDoBRule {
  public validator = (dayOfBirthStr: string) => {
    return this.check(Number.parseInt(dayOfBirthStr), (playerDoB) => playerDoB.toDate().getDate());
  };
  public message() {
    return 'Incorrect Date';
  }
}

export class MonthRule extends EmptyDoBRule {
  public validator = (monthOfBirthStr: string) => {
    return this.check(
      Number.parseInt(monthOfBirthStr),
      (playerDoB) => playerDoB.toDate().getMonth() + 1
    );
  };
  public message() {
    return 'Incorrect Month';
  }
}

export class YearRule extends EmptyDoBRule {
  public validator = (yearOfBirthStr: string) => {
    return this.check(Number.parseInt(yearOfBirthStr), (playerDoB) =>
      playerDoB.toDate().getFullYear()
    );
  };
  public message() {
    return 'Incorrect Year';
  }
}
