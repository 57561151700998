import * as React from 'react';
import {
  Color,
  Expect,
  FlexDirection,
  FlexWrapper,
  Overlay,
  ProgressBar,
  RichText,
  SPOT_RESERVATION_EXPIRED_MESSAGE,
  Text,
} from '@usga/modules';
import { FlowType, IGenericAppliance } from '../../interfaces';
import { FlowManager, IFlowManagerRenderArgs } from '../FlowManager';
import { finalize, map, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { mergeWith, omit } from 'lodash';
import { ApplicationResponseModel, ProfilePlayerModel } from '@usga/champadmin-api';
import BackToListButton from '../../../BackToListButton';
import * as Utils from '../utils';
import { hasForceStep } from '../utils';
import { ProfilePlayerModelContext } from '../../steps/ApplicationHome/sections/general-information/DoBFields';
import { IGenericFlowProps, IGenericFlowState } from '../interfaces';
import { StepsWrapper } from './components';
import CountdownTimer from '../../steps/CountdownTimer/CountdownTimer';
import ReservedSpotModal from '../../steps/ReservedSpotModal/ReservedSpotModal';

export const ADAPTIVE_SPECIFIC_FLOW_FIELDS = [
  'wR4GDPass',
  'wr4GDPass',
  'disabilityDesc',
  'disabilityType',
  'virtusII1Number',
  'playGolfInCart',
  'adaptiveEquipDesc',
  'compPlayingRecord',
  'legalGuardian',
  'files',
  'fileIds',
];

export class GenericFlowComponent<T extends IGenericAppliance> extends React.Component<
  IGenericFlowProps<T>,
  IGenericFlowState<T>
> {
  static meta: any = {};

  private TIMER_STYLE = {
    position: 'absolute',
    zIindex: 2,
    marginTop: '-33px',
    width: '100%',
    color: 'white',
    background: 'red',
    height: '25px',
  };

  public constructor(props: IGenericFlowProps<T>) {
    super(props);

    this.state = {
      steps: new Array(props.steps.length).fill(0).map((_, i) => i),
      initialData: undefined,
      isLoading: true,
      profile: null,
      showTimer: false,
      reservedSpotExpired: false,
      qualifyingPageStepIndex: 0,
    };
  }

  public showTimer(
    enable: boolean,
    qualifyingPageStepIndex: number,
    id?: number,
    expiryTime?: string,
    qualifyingSiteId?: number,
    initialReserveTime?: number,
    finalReserveTime?: number
  ) {
    this.setState({
      showTimer: enable,
      reserveSpotId: id,
      reserveSpotExpiry: expiryTime,
      currentlyReservedSiteId: qualifyingSiteId,
      initialReserveTime,
      finalReserveTime,
      qualifyingPageStepIndex: qualifyingPageStepIndex,
    });
  }

  public componentDidMount() {
    if (this.props.authResult.idToken) {
      this.getInitialData();
    }
  }

  public componentDidUpdate(prevProps: Readonly<IGenericFlowProps<T>>) {
    if (prevProps.authResult.idToken !== this.props.authResult.idToken) {
      this.getInitialData();
    }
  }

  public render() {
    if (this.state.error) {
      return (
        <FlexWrapper direction={FlexDirection.COLUMN}>
          <Text color={Color.ALERT}>{this.props.errorMessages.title}</Text>
          <RichText html={this.state.error} />
          <BackToListButton />
        </FlexWrapper>
      );
    }

    return (
      <Overlay active={this.state.isLoading}>
        <FlowManager steps={this.props.steps} initialData={this.state.initialData}>
          {this.renderFlow}
        </FlowManager>
      </Overlay>
    );
  }

  private getInitialData() {
    if (this.state.initialData) {
      console.warn('Cannot reload initial data');
      return;
    }
    forkJoin([of(this.props.profile), of(this.props.application), of(this.props.championships)])
      .pipe(
        tap(([, application, championships]) => {
          if ('championship' in application && 'paid' in championships) {
            const paidChamps = championships.paid;
            const waitlistedChamps = championships.waitlisted;
            const champId = application.championship.id;

            const paidOrWaitlisted =
              waitlistedChamps.includes(champId) || paidChamps.includes(champId);

            if (paidOrWaitlisted && !hasForceStep() && !application.declinedPayment) {
              this.setState({ error: this.props.errorMessages.text });
            }
          }
        }),
        map(([profile, application]): [{} | ProfilePlayerModel, {} | ApplicationResponseModel] => {
          const newApplication = hasForceStep() ? application : Utils.resetApplication(application);
          return [profile, newApplication];
        }),
        finalize(() => this.setState({ isLoading: false }))
      )
      .subscribe(([profile, application]) => {
        const filteredProfile =
          this.props.flowType === FlowType.Adaptive
            ? profile
            : omit(profile, ADAPTIVE_SPECIFIC_FLOW_FIELDS);
        const profileValue = filteredProfile && 'dob' in filteredProfile ? filteredProfile : null;
        const initialData = mergeWith(application, filteredProfile, (objValue, srcValue) => {
          if (Array.isArray(objValue)) {
            return objValue.concat(srcValue);
          }
        }) as Partial<T>;
        this.setState({
          initialData: initialData,
          profile: profileValue,
        });
      });
  }

  private reservedSpotExpired = () => {
    this.releaseReservedSpotApiCall(this.state.reserveSpotId).subscribe(
      () => {
        this.openExpiredPopup();
      },
      () => {
        this.openExpiredPopup();
      }
    );
  };

  private openExpiredPopup = () => {
    this.setState({
      reservedSpotExpired: true,
      reserveSpotExpiry: '',
      currentlyReservedSiteId: undefined,
    });
  };

  private releaseReservedSpotApiCall = (reserveSpotId?: number) => {
    return this.props.httpService.get('/player/release/reserve_spot?id=' + reserveSpotId);
  };

  private reserveSpotReleased = () => {
    this.setState({
      reserveSpotExpiry: '',
      currentlyReservedSiteId: undefined,
      reserveSpotId: undefined,
    });
  };

  private renderFlow = ({ stepIndex, manager, data }: IFlowManagerRenderArgs<T>) => {
    const CurrentComponent = this.props.steps[stepIndex];

    const gotoQualifyingStep = (step?: number) => {
      manager.jumpToComponent(data || {}, step || this.state.qualifyingPageStepIndex);
      this.setState({ reservedSpotExpired: false });
    };

    return (
      <>
        {this.state.showTimer && this.state.reserveSpotExpiry && (
          <CountdownTimer
            key={this.state.reserveSpotExpiry}
            limitInMinutes={this.state.initialReserveTime}
            style={this.TIMER_STYLE}
            onReservedSpotExpired={this.reservedSpotExpired.bind(this)}
          ></CountdownTimer>
        )}

        <ReservedSpotModal
          isOpened={this.state.reservedSpotExpired}
          text={SPOT_RESERVATION_EXPIRED_MESSAGE}
          onClose={() => gotoQualifyingStep()}
          onSubmit={() => gotoQualifyingStep()}
        />

        <ProfilePlayerModelContext.Provider value={this.state.profile}>
          <ProgressBar
            title={'Application Progress'}
            steps={this.state.steps}
            activeStep={stepIndex}
          />
          <StepsWrapper>
            <Overlay active={!this.state.initialData}>
              <Expect value={CurrentComponent}>
                {(CurrentComponent) => (
                  <CurrentComponent
                    {...this.props.aemProps}
                    steps={this.props.steps}
                    meta={GenericFlowComponent.meta}
                    manager={manager}
                    data={data}
                    flowType={this.props.flowType}
                    initialData={this.state.initialData}
                    showTimer={this.showTimer.bind(this)}
                    reserveSpotId={this.state.reserveSpotId}
                    reserveSpotExpiry={this.state.reserveSpotExpiry}
                    currentlyReservedSiteId={this.state.currentlyReservedSiteId}
                    initialReserveTime={this.state.initialReserveTime}
                    finalReserveTime={this.state.finalReserveTime}
                    onReservedSpotReleased={this.reserveSpotReleased.bind(this)}
                    gotoQualifyingStep={gotoQualifyingStep.bind(this)}
                    stepIndex={stepIndex}
                    partnerApplication={this.props.partnerApplication}
                  />
                )}
              </Expect>
            </Overlay>
          </StepsWrapper>
        </ProfilePlayerModelContext.Provider>
      </>
    );
  };
}
