import { ValidatorForm } from './ValidatorForm';
import { ISchemaValidator } from '@usga/champadmin-api/validators/helpers/compileSchema';
import ajv from 'ajv';
import { IValidationResult, IValidatorManager } from './ValidatorManager';
import { isEqual, flatten } from 'lodash';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { IObjectMapperSchemaV2, ObjectMapperV2 } from '../../../services/objectMapperV2';

export type IAjvErrorHit = { field: string; result: IValidationResult };

export enum AjvErrorType {
  MissingProperty,
  Unknown,
}

export class AjvValidatorForm<S extends object, F extends object> extends ValidatorForm<F> {
  private objectMapper: ObjectMapperV2<S, F>;

  private source: Partial<S>;

  private ajvValidator: ISchemaValidator;

  private ajvSourcePath?: keyof S;

  private lastValue$: BehaviorSubject<F>;

  private formatErrors: (type: AjvErrorType, error: ajv.ErrorObject) => string;

  constructor(
    source: Partial<S>,
    ajvValidator: ISchemaValidator,
    schema: IObjectMapperSchemaV2<Partial<S>, F>,
    options?: {
      ajvSourcePath?: keyof S;
      formatErrors?: (type: AjvErrorType, error: ajv.ErrorObject) => string;
    }
  ) {
    const objectMapper = new ObjectMapperV2<S, F>(schema);
    const data = objectMapper.fromSourceToTarget(source);

    super(data);
    this.lastValue$ = new BehaviorSubject(data);
    this.objectMapper = objectMapper;
    this.source = source;
    this.ajvValidator = ajvValidator;
    this.ajvSourcePath = options?.ajvSourcePath;
    this.formatErrors = options?.formatErrors || AjvValidatorForm.defaultErrorFormatter;
  }

  private static defaultErrorFormatter(type: AjvErrorType, error: ajv.ErrorObject) {
    if (type === AjvErrorType.MissingProperty) {
      return 'Field is required.';
    }
    return AjvValidatorForm.toPascalCase(error.message);
  }

  private static toPascalCase(str?: string) {
    return str && str.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : '';
  }

  // false on init, updateSource and updateProperty
  get isDirty() {
    return !isEqual(this.lastValue$.getValue(), this.getFormData());
  }

  updateProperty<K extends keyof F>(key: K, value: F[K]) {
    super.changeProperty(key, value);
    this.lastValue$.next({
      ...this.lastValue$.getValue(),
      [key]: value,
    });
  }

  observeDirty() {
    return combineLatest([this.lastValue$.asObservable(), this.observeForm()]).pipe(
      map(([lastValue, form]) => {
        return !isEqual(lastValue, form);
      }),
      distinctUntilChanged()
    );
  }

  getAjvErrors(): IAjvErrorHit[] | null {
    const data = this.getMappedData();
    const ajvData = this.ajvSourcePath ? data[this.ajvSourcePath] : data;
    const ajvErrors = this.ajvValidator.json(ajvData);
    if (ajvErrors) {
      return flatten(
        ajvErrors.map((e) => {
          return this.lookupFieldByError(e, data, this.getFormData());
        })
      ).filter((e) => e != null) as IAjvErrorHit[];
    }
    return null;
  }

  getMappedData(visibleOnly = true) {
    return this.objectMapper.mergeDataWithForm(this.source, this, visibleOnly);
  }

  lookupFieldByError(error: ajv.ErrorObject, source: Partial<S>, target: F): IAjvErrorHit[] {
    const trimDot = (s: string) => (s[0] === '.' ? s.slice(1) : s);
    const missingProperty =
      'missingProperty' in error.params ? error.params.missingProperty : void 0;
    const originalPath = [trimDot(error.dataPath), missingProperty].filter((e) => !!e).join('.');
    const fields = this.objectMapper.getFieldsByOriginalPath(originalPath, source, target);

    if (fields.length) {
      return fields.map((field) => ({
        field,
        result: {
          isValid: false,
          isWarning: false,
          message: this.formatErrors(this.getAjvErrorType(error), error),
        },
      }));
    } else {
      console.warn('Cannot find path for error', error);
      return [];
    }
  }

  updateSource(source: Partial<S>) {
    this.source = source;
    const data = this.objectMapper.fromSourceToTarget(this.source);

    this.setFormData(data);
    this.lastValue$.next(data);
  }

  protected checkValidators(
    validators: IValidatorManager[],
    goToAnchor = true
  ): Promise<string[] | undefined | void> {
    const errors = this.getAjvErrors();
    const getHit = (field: string) => errors?.find((e) => e.field === field)?.result;
    return Promise.all(
      validators.map((validator: IValidatorManager) => validator.validate(getHit(validator.field)))
    ).then((validatorErrors) => {
      return this.processErrors(validatorErrors, goToAnchor);
    });
  }

  protected getAjvErrorType(error: ajv.ErrorObject) {
    if ('missingProperty' in error.params) {
      return AjvErrorType.MissingProperty;
    }
    return AjvErrorType.Unknown;
  }
}
